import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/*
 * Create new theme
 *
 * returns Object {data, error, status}
 */
export async function createNewTheme(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.theme.create_theme, payload, {
      headers,
      timeout: 400000,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get all themes
 *
 * returns Object {data, error, status}
 */
/**
 *
 * @param {
  "ascending": true,//optional
  "maxDate": "2022-02-01T07:34:23.052Z",//optional
  "minDate": "2022-02-01T07:34:23.053Z",//optional
  "pageNumber": 0,
  "priceMax": 0,//optional
  "priceMin": 0,//optional
  "size": 0,
  "sortBy": "string",//optional
  "themeId": "string",//optional
  "userId": "string"//optional
} payload
 * @returns
 */
export async function getAllThemes(payload) {
  try {
    const response = await appClient.post(apiConfig.theme.get_all_themes, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get purchased themes
 *
 * returns Object {data, error, status}
 */
/**
 *
 * @param {
  "ascending": true,//optional
  "maxDate": "2022-02-01T07:34:23.052Z",//optional
  "minDate": "2022-02-01T07:34:23.053Z",//optional
  "pageNumber": 0,
  "priceMax": 0,//optional
  "priceMin": 0,//optional
  "size": 0,
  "sortBy": "string",//optional
  "themeId": "string",//optional
  "userId": "string"//optional
} payload
 * @returns
 */
export async function getPurchasedThemes(payload) {
  try {
    const response = await appClient.post(apiConfig.theme.get_purchased_themes, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function activateDeactivateTheme(themeId, activeStatus) {
  const url = apiConfig.theme.active_deactivate_theme + themeId + '/' + activeStatus;
  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Equip a theme
 * @param {userId: "", themeId: ""} payload
 * @returns
 */
export async function equipTheme(payload) {
  try {
    const response = await appClient.put(apiConfig.theme.equip_theme, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get the client secret and intent id for stripe
 * @params {String} themeId
 * @returns {client_secret: "", id: ""}
 */
export async function getStripeClientSecret(themeId) {
  try {
    const response = await appClient.post(apiConfig.theme.get_stripe_id + `/${themeId}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
/**
 * get active triggers
 *
 * returns Object {data, error, status}
 */
export async function getActiveTriggers() {
  try {
    const response = await appClient.get(apiConfig.theme.get_active_triggers);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get all triggers
 *
 * returns Object {data, error, status}
 */
export async function getAllTriggers() {
  try {
    const response = await appClient.get(apiConfig.theme.get_all_tiggers);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * called after successful stripe payment
 * @param {userId: "", themeId: ""} payload
 * @returns
 */
export async function purchaseTheme(payload) {
  try {
    const response = await appClient.put(apiConfig.theme.purchase_theme, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * cancel the stripe payment
 * @params {String} stripe payment intent id
 * @returns {}
 */
export async function cancelStripePayment(id) {
  try {
    const response = await appClient.put(apiConfig.theme.cancel_payment + `/${id}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
