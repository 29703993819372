import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  getAllUsersWithCount as getAllUsersWithCountFromAPI,
  getUserId,
  isUserLoggedIn,
  loginUserInApp,
  getUserCounters as getUserCountersFromAPI,
  getAllWorkspaceUsers as getAllWorkspaceUsersFromAPI,
  getUserAccountSettings as getUserAccountSettingsFromAPI,
  getUserTutorials as getUserTutorialsFromAPI,
  getAllWorkspaceUsersList as getAllWorkspaceUsersListFromAPI,
  isAdmin,
} from 'services/AuthService';
import {
  getAllThemes as getAllThemesFromAPI,
  getPurchasedThemes as getPurchasedThemesFromAPI,
} from 'services/ThemeService';
import { getWhatsNew as getWhatsNewFromAPI } from 'services/WhatsNewService';
import { getNewNotifications as getNewNotificationsFromAPI } from 'services/NotificationsService';
import { getAllTags as getAllTagsAPI } from 'services/TagService';
import {
  allUsersState,
  openCountState,
  completedCountState,
  focusPointsState,
  backlogPointsState,
  accountDataSelector,
  invitedMembersState,
  accountState,
  whatsNewState,
  allTagsState,
  accountSettingsState,
  allTutorialsState,
  allThemesState,
  purchasedThemesState,
  invitedMembersPaginatedListState,
  invitedMembersPaginatedSortByState,
  invitedMembersPaginatedSortOrderState,
  invitedMembersPaginatedSearchKeyState,
  invitedMembersPaginatedActiveState,
  invitedMembersPaginatedAbortControllerState,
  invitedMembersPaginatedDataState,
  invitedMembersPaginatedHasMoreState,
  invitedMembersPaginatedPageState,
  invitedMembersPaginatedIsLoadingState,
  isUnreadNotificationLoadingState,
  hasMoreUnreadNotificationState,
  currentNotificationUnreadPageState,
  isAllNotificationLoadingState,
  hasMoreAllNotificationState,
  currentAllPageNotificationState,
  allNotificationsState,
  unreadNotificationCountsState,
  unreadNotificationsState,
} from '.';
import { allUserTagsState } from 'recoil/UserState';
import {
  formatDateForView,
  parseTags,
  parseThemes,
  parseToDateObject,
  parseTutorials,
  parseUser,
  parseUsers,
  parseUsersWithCount,
} from 'utils/task';
import { showWhatsNewModalState } from 'recoil/NavigationState';
import { initializeTriggers } from 'triggers';

export function addAllUsers(data: UserFieldType[]) {
  setRecoil(allUsersState, data);
}

export async function getAllUsersWithCount() {
  const { data, success } = await getAllUsersWithCountFromAPI();

  if (success && data) {
    const allUsers = parseUsersWithCount(data);
    addAllUsers(
      allUsers.map((item) => {
        return item.user;
      }),
    );
  }
}

export function updateWorkspace(workspace: WorkspaceType) {
  const userData = isUserLoggedIn();
  userData.workspace = workspace;
  if (userData.user) {
    userData.user.workspace = workspace;
    if (userData.user.workspaces && userData.user.workspaces.length > 0) {
      const index = userData.user.workspaces.findIndex((ws) => ws.id === workspace.id);
      userData.user.workspaces[index] = workspace;
    }
  }

  loginUserInApp(userData);

  const account = getRecoil(accountState);
  if (!account) return;
  if (account.workspace && workspace && account.workspace.id === workspace.id) {
    const temp = {
      ...account,
      workspace: workspace,
    };
    setRecoil(accountState, temp);
  }
  if (account.workspaces && account.workspaces.length > 0) {
    const tempData: UserFieldType = {
      ...account,
      workspaces: account.workspaces.map((item) => {
        if (item.id === workspace.id) item = workspace;
        return item;
      }),
    };
    setRecoil(accountState, tempData);
  }
}

export function setUserCounters(
  open: number,
  completed: number,
  focusPoints: number,
  backlogPoints: number,
) {
  setRecoil(openCountState, open);
  setRecoil(completedCountState, completed);
  setRecoil(focusPointsState, focusPoints);
  setRecoil(backlogPointsState, backlogPoints);
}

export async function getUserCounters() {
  const { data, success } = await getUserCountersFromAPI();

  if (success && data.length) {
    const user = data.filter((count) => {
      return count.user.id === getUserId();
    });
    if (user && user.length) {
      setUserCounters(
        user[0].openTasks ? user[0].openTasks : 0,
        user[0].recentCompletedCount ? user[0].recentCompletedCount : 0,
        user[0].focusPoints ? user[0].focusPoints : 0,
        user[0].backlogPoints ? user[0].backlogPoints : 0,
      );
    } else {
      setUserCounters(0, 0, 0, 0);
    }
  } else if (success) {
    setUserCounters(0, 0, 0, 0);
  }
}

export async function getNewNotifications(status: NotificationStatusType, page: number) {
  const accountData = getRecoil(accountDataSelector);
  if (status === 'Un-read') {
    updateUnreadNotificationCountLoader(true, false, page);
  } else {
    updateAllNotificationCountLoader(true, false, page);
  }
  const { data } = await getNewNotificationsFromAPI(status, page);

  if (data && data.notifications && data.notifications.length > 0) {
    if (status === 'Un-read') {
      if (data.notifications.length < 10) {
        updateUnreadNotificationCountLoader(false, false, page);
      } else {
        updateUnreadNotificationCountLoader(false, true, page + 1);
      }
      if (page === 0) updateUnreadNotificationList(data.notifications, data.unreadCounts);
      else
        updateUnreadNotificationList(
          [...accountData.notifications.unreadNotifications, ...data.notifications],
          data.unreadCounts,
        );
    } else {
      if (data.notifications.length < 10) {
        updateAllNotificationCountLoader(false, false, page);
      } else {
        updateAllNotificationCountLoader(false, true, page + 1);
      }
      if (page === 0) updateAllNotificationList(data.notifications, data.unreadCounts);
      else
        updateAllNotificationList(
          [...accountData.notifications.allNotifications, ...data.notifications],
          data.unreadCounts,
        );
    }
  } else {
    if (status === 'Un-read') {
      updateUnreadNotificationCountLoader(false, false, page);
    } else {
      updateAllNotificationCountLoader(false, false, page);
    }
  }
}

export function updateUnreadNotificationCountLoader(
  loading: boolean,
  hasMore: boolean,
  currentPage: number,
) {
  setRecoil(isUnreadNotificationLoadingState, loading);
  setRecoil(hasMoreUnreadNotificationState, hasMore);
  setRecoil(currentNotificationUnreadPageState, currentPage);
}

export function updateAllNotificationCountLoader(
  loading: boolean,
  hasMore: boolean,
  currentPage: number,
) {
  setRecoil(isAllNotificationLoadingState, loading);
  setRecoil(hasMoreAllNotificationState, hasMore);
  setRecoil(currentAllPageNotificationState, currentPage);
}

export function updateAllNotificationList(data: NotificationObjectType[], unreadCounts: number) {
  setRecoil(allNotificationsState, data);
  setRecoil(unreadNotificationCountsState, unreadCounts);
}

export function updateUnreadNotificationList(data: NotificationObjectType[], unreadCounts: number) {
  setRecoil(unreadNotificationsState, data);
  setRecoil(unreadNotificationCountsState, unreadCounts);
}

export async function getInvitedMembers() {
  const { data, success } = await getAllWorkspaceUsersFromAPI();
  if (success && data) addInvitedMembers(parseUsers(data));
}

export function addInvitedMembers(data: UserFieldType[]) {
  setRecoil(invitedMembersState, data);
}

export function addAccountImage(imageUrl: string) {
  const account = getRecoil(accountState);
  if (account) {
    const tempData = {
      ...account,
      profileImage: imageUrl,
    };
    setRecoil(accountState, tempData);
  }
}

export function updateWorkspaceSequence(sequences: number[]) {
  const account = getRecoil(accountState);
  if (!account) return;
  const tempData: UserFieldType = {
    ...account,
    workspaceSequences: sequences,
  };
  setRecoil(accountState, tempData);
}

export async function getWhatsNew() {
  const { data, success } = await getWhatsNewFromAPI();

  if (success && data && data.length) {
    setRecoil(whatsNewState, {
      id: data[0].id ? data[0].id : null,
      title: data[0].title ? data[0].title : '',
      headerImage: data[0].headerImage ? data[0].headerImage : null,
      date: data[0].addedOn
        ? formatDateForView(parseToDateObject(data[0].addedOn), 'MMM D, YYYY')
        : '',
      features: data[0].features ? data[0].features : [],
      gotData: true,
    });

    if (!data[0].notified) setRecoil(showWhatsNewModalState, true);
  }
}

export async function getAllTags(userId: string) {
  const { data, success } = await getAllTagsAPI(userId);

  if (success && data) setRecoil(allTagsState, parseTags(data) as TagType[]);
}

export async function getAccountSettings(userId: string, initTriggers?: boolean) {
  const { data, success } = await getUserAccountSettingsFromAPI(userId);

  if (success) setRecoil(accountSettingsState, data);

  if (initTriggers) initializeTriggers(true);
}

export async function getTutorialsData() {
  const { data, success } = await getUserTutorialsFromAPI();

  if (success && data) setRecoil(allTutorialsState, parseTutorials(data));
}

export async function getAllThemes() {
  const payload = {
    pageNumber: 0,
    size: 100,
  };
  const { data, success } = await getAllThemesFromAPI(payload);

  if (success && data)
    setRecoil(allThemesState, parseThemes(data, false) as ThemeObjectInterface[]);
}

export async function getPurchasedThemes() {
  const payload = {
    pageNumber: 0,
    size: 100,
  };
  const { data, success } = await getPurchasedThemesFromAPI(payload);

  if (success && data)
    setRecoil(purchasedThemesState, parseThemes(data, true) as ThemeInstanceInterface[]);
}

export async function getAllUserTags(userId: string, reset: boolean) {
  if (reset) setRecoil(allUserTagsState, []);

  const { data, success } = await getAllTagsAPI(userId);

  if (success && data) setRecoil(allUserTagsState, parseTags(data) as TagType[]);
}

function setInvitedMembersSortBy(
  sortByName: SortByType,
  invitedMembersList: InvitedMembersPaginatedListType,
) {
  let { sortBy, sortOrder } = invitedMembersList;

  if (sortBy !== sortByName) {
    sortBy = sortByName;
    sortOrder = 'asc';
  } else {
    if (sortOrder === 'asc') sortOrder = 'desc';
    else sortOrder = 'asc';
  }
  setRecoil(invitedMembersPaginatedSortByState, sortBy);
  setRecoil(invitedMembersPaginatedSortOrderState, sortOrder);
}

export function addInvitedMembersList(
  data: UserFieldType[],
  hasMore?: boolean,
  page?: number,
  invitedMembersList?: InvitedMembersPaginatedListType,
) {
  if (invitedMembersList === undefined) {
    invitedMembersList = getRecoil(invitedMembersPaginatedListState);
  }
  setRecoil(invitedMembersPaginatedDataState, data);
  setRecoil(
    invitedMembersPaginatedHasMoreState,
    hasMore === undefined ? invitedMembersList.hasMore : hasMore,
  );
  setRecoil(invitedMembersPaginatedPageState, page === undefined ? invitedMembersList.page : page);
}

function mergeUsersData(oldData: UserFieldType[], newData: UserFieldType[]) {
  newData.forEach((newDat, _index) => {
    if (newDat.id) {
      const index = oldData.findIndex((data) => data.id === newDat.id);

      if (index === -1) oldData = [...oldData, parseUser(newDat)];
    }
  });
  return oldData;
}

export async function getInvitedMembersList(
  reset: boolean,
  sortBy: SortByType | null,
  searchKey: string,
  active: boolean | null,
) {
  // set filter in redux
  const invitedMembersList = getRecoil(invitedMembersPaginatedListState);
  if (sortBy) {
    setInvitedMembersSortBy(sortBy, invitedMembersList);
  } else {
    sortBy = invitedMembersList.sortBy;
  }

  if (searchKey !== null && searchKey !== undefined && invitedMembersList.searchKey !== searchKey) {
    setRecoil(invitedMembersPaginatedSearchKeyState, searchKey);
  } else {
    searchKey = invitedMembersList.searchKey;
  }

  if (active !== undefined && active !== null) {
    setRecoil(invitedMembersPaginatedActiveState, active);
  } else {
    active = invitedMembersList.active;
  }

  if (reset) {
    const abortController = invitedMembersList.abortController;
    abortController.abort();

    const newAbortSource = new AbortController();
    setRecoil(invitedMembersPaginatedAbortControllerState, newAbortSource);
    await addInvitedMembersList([], true, 0, invitedMembersList);
  }

  setRecoil(invitedMembersPaginatedIsLoadingState, true);

  const { limit, sortOrder } = invitedMembersList;
  let { page } = invitedMembersList;

  if (reset) page = 0;

  const payload = {
    pageNumber: page,
    size: limit,
    sortBy: sortBy,
    active: active,
    searchKey: searchKey,
    ascending: sortOrder === 'asc' ? true : false,
  };
  const { data, success } = await getAllWorkspaceUsersListFromAPI(payload);

  if (success && data && Array.isArray(data) && data.length > 0) {
    const newUsers = parseUsers(data);
    if (reset) {
      addInvitedMembersList(
        newUsers,
        limit > data.length ? false : true,
        page + 1,
        invitedMembersList,
      );
    } else {
      addInvitedMembersList(
        mergeUsersData(invitedMembersList.data, newUsers),
        limit > data.length ? false : true,
        page + 1,
        invitedMembersList,
      );
    }
  } else {
    setRecoil(invitedMembersPaginatedHasMoreState, false);
  }

  setRecoil(invitedMembersPaginatedIsLoadingState, false);
}

export function updateThemeStatus(index: number, status: boolean) {
  const allThemes = getRecoil(allThemesState);
  const tempData = allThemes.map((theme, ind) => {
    if (index === ind) theme.active = status;
    return theme;
  });
  setRecoil(allThemesState, tempData);
}

export function addUser(data: ResponseUserType) {
  const res = parseUser(data);
  let allUsers: UserFieldType[] | null = getRecoil(allUsersState);
  if (allUsers === null) allUsers = [];
  const tempU = allUsers.filter((item) => item.id !== data.userId);
  const tempData = [...tempU, res];
  setRecoil(allUsersState, tempData);
}

export function removeUser(data: string) {
  const allUsers = getRecoil(allUsersState);
  if (allUsers === null) return;

  const tempData = allUsers.filter((item) => item.id !== data);
  setRecoil(allUsersState, tempData);

  if (isAdmin()) {
    const paginatedUsers = getRecoil(invitedMembersPaginatedDataState);
    const tempData = paginatedUsers.filter((item) => item.id !== data);
    setRecoil(invitedMembersPaginatedDataState, tempData);
  }
}

export function updateUsersCounters(
  userId: string,
  focusCount: number,
  completedCount: number,
  focusPoints: number,
  backlogPoints: number,
) {
  const allUsers = getRecoil(allUsersState);
  if (allUsers === null) return;

  const tempData = allUsers.map((item) => {
    if (item.id === userId)
      return {
        ...item,
        focusCount,
        completedCount,
        focusPoints,
        backlogPoints,
      };
    return item;
  });

  setRecoil(allUsersState, tempData);
}
