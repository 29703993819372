/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo, useRef, memo, useCallback } from 'preact/compat';
import { Collapse } from 'react-collapse';
import ViewportList from 'react-viewport-list';
import { log } from 'utils';
import { isMacOS, TASK_INPUT_WAIT_INTERVAL } from 'utils/helpers';
import { currentDay } from 'utils/task';
import Spinner from 'components/UI/Spinner';
import IndividualItem from './IndividualItem';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import moment, { Moment } from 'moment';
import _ from 'underscore';
import Animations from 'animations';
import { getUserId, isAdmin } from 'services/AuthService';
import { runCanvasAnimation } from 'triggers/utils/canvas';
import { useRecoilValue } from 'recoil';
import { projectDataSelector } from 'recoil/ProjectState';
import { accountSettingsState } from 'recoil/AccountState';
import { userDataSelector } from 'recoil/UserState';
import {
  assigneeFilterSelector,
  currentHighlightedTaskSelector,
  dateFilterSelector,
  projectFilterSelector,
  sizeFilterSelector,
  tagFilterSelector,
  taskDataStateSelector,
  taskIsLoadingSelector,
} from 'recoil/TaskState';
import { templateDataSelector } from 'recoil/TemplateState';
import {
  addNewSubtask,
  updateTask as updateRecurringTask,
  updateTaskName as updateRecurringTaskNameInRecoil,
  deleteTask as deleteRecurringTask,
  checkTask as checkRecurringTask,
} from 'recoil/RecurringTaskState/update';
import {
  deleteTemplateRecurringTask,
  updateTask as updateTemplateTask,
} from 'recoil/TemplateState/update';
import {
  addTaskInBetween,
  deleteTask as deleteTaskRecoil,
  saveTaskCollapsableState,
  saveTaskName as saveTaskNameInRecoil,
  setHighlightedTask,
  updateTaskAnimation,
  updateTaskTags as updateTaskTagsInRecoil,
} from 'recoil/TaskState/update';
import { allSizesSelector } from 'recoil/SizeState';
import { triggerDataSelector } from 'recoil/TriggerState';
import { unCheckTask } from 'recoil/CompletedTasksState/update';
import { canBePlayed } from 'triggers/utils/rive';
import { Tooltip } from 'react-tooltip';

const DEFAULT_SIZE = 'M';

type TaskItemsPropsInterface = {
  stateType: StateType;
  dataType: TaskType;
  title: string;
  readOnly?: boolean;
  tasks: TaskListType;
  storeInputRefs: (id: string, inputRef: HTMLElement) => void;
  toggleSelectionInGroup: (id: string, tempId: string, parentId?: string) => void;
  multiSelectTo: (id: string, tempId: string, parentId?: string) => void;
  toggleSelection: (id: string, tempId: string, parentId?: string) => void;
  selectedTasks: SelectedTaskIdsType[];
  updateSizeGroup: (
    index: number,
    id: string,
    size: SizeOptionsType,
    parentIndex?: number,
    parentId?: string,
    dataType?: TaskType,
    stateType?: StateType,
  ) => void;
  saveTaskAssignee: (
    newAssigneeId: string,
    dataType: TaskType,
    index: number,
    taskId: string,
    parentIndex?: number,
    parentId?: string,
    taskName?: string,
    stateType?: StateType,
  ) => void;
  saveTasksProject: (
    index: number,
    taskId: string,
    newProjectId: string | null,
    parentIndex: number | undefined,
    parentId: string | undefined,
    dataType: TaskType,
    stateType?: StateType,
    taskName?: string,
  ) => void;
  saveTasksStartEndDate: (
    dataType: TaskType,
    index: number,
    taskId: string,
    startDate: Date | null,
    endDate: Date | null,
    parentIndex?: number,
    parentId?: string,
    stateType?: StateType,
  ) => void;
  saveTasksDueDate: (
    dataType: TaskType,
    index: number,
    taskId: string,
    date: Date,
    parentIndex?: number,
    parentId?: string,
    stateType?: StateType,
  ) => void;
  checkTasks: (
    index: number,
    id: string,
    dataType: TaskType,
    parentIndex: number | undefined,
    parentId: string | undefined,
    stateType: StateType | undefined,
    checked: boolean,
  ) => void;
  selectedTaskIds: SelectedTaskIdsType[];
  draggingTaskId: string | null;
  bulkUpdateTasksTag: (
    tagId: string,
    name: string,
    tempId: string,
    taskId: string,
    add: boolean,
    selectedCount?: number,
  ) => void;
  hideDragIcon: boolean;
  selectedNotificationTaskId?: string | null;
};

const filenames = [
  'task-creation-animation-1',
  'task-creation-animation-2',
  'task-creation-animation-3',
  'task-creation-animation-4',
  'task-creation-animation-5',
];

function TaskItems(props: TaskItemsPropsInterface) {
  const [animations, setAnimations] = useState<AnimationObjectType[]>([]);

  const userId = getUserId();
  const triggerData = useRecoilValue(triggerDataSelector);
  const projectData = useRecoilValue(projectDataSelector);
  const userData = useRecoilValue(userDataSelector);
  const taskData = useRecoilValue(taskDataStateSelector);
  const templateData = useRecoilValue(templateDataSelector);
  const ref = useRef(null);

  const {
    stateType,
    dataType,
    title,
    readOnly,
    tasks,
    storeInputRefs,
    toggleSelectionInGroup,
    multiSelectTo,
    toggleSelection,
    selectedTasks,
    updateSizeGroup,
    saveTaskAssignee,
    saveTasksProject,
    saveTasksStartEndDate,
    saveTasksDueDate,
    checkTasks,
    selectedTaskIds,
    draggingTaskId,
    bulkUpdateTasksTag,
    hideDragIcon,
    selectedNotificationTaskId,
  } = props;
  const accountSettings = useRecoilValue(accountSettingsState);
  const assignDefaultToSelf = accountSettings.assignDefaultToSelf;

  const sizeFilter = useRecoilValue(sizeFilterSelector(stateType));

  const currentHighlightedTask = useRecoilValue(currentHighlightedTaskSelector(stateType));

  const dateFilter = useRecoilValue(dateFilterSelector(stateType));

  const tagFilter = useRecoilValue(tagFilterSelector(stateType));

  const assigneeFilter = useRecoilValue(assigneeFilterSelector(stateType));

  const projectFilter = useRecoilValue(projectFilterSelector(stateType));

  const isLoading = useRecoilValue(taskIsLoadingSelector({ dataType, stateType }));

  const allSizes = useRecoilValue(allSizesSelector);

  const getStateData = () => {
    let state: any = [];

    if (stateType === 'PROJECT') state = projectData;
    else if (stateType === 'INDIVIDUAL') state = userData;
    else state = taskData;

    return state;
  };

  const getCompletedPoints = getStateData().COMPLETED?.points;

  /*
   * State for date refs
   */
  const [dateRefs, setDateRefs] = useState({});

  /*
   * Store date refs
   *
   * id String
   * ref Object
   *
   * returns null
   */
  const storeDateRefs = (id: string, ref: HTMLElement) => {
    dateRefs[id] = ref;
    setDateRefs(dateRefs);
  };

  /*
   * On change date event
   *
   * dates Array
   * tempId String
   *
   * returns React.DOM
   */
  const onChangeDate = (
    index: number,
    taskId: string,
    dates: Date[] | null,
    tempId: string,
    parentIndex?: number,
    parentId?: string,
  ) => {
    const [start, end] = dates ? dates : [null, null];

    if (start && end) {
      dateRefs[tempId] && dateRefs[tempId].setOpen(false);
    }

    updateStartEndDates(index, taskId, start, end, parentIndex, parentId);
  };

  /*
   * On change due date event
   *
   * dates Array
   * tempId String
   *
   * returns React.DOM
   */
  const onChangeDueDate = (
    index: number,
    taskId: string,
    date: Date,
    tempId: string,
    parentIndex?: number,
    parentId?: string,
  ) => {
    log('onChangeDueDate', date, taskId, tempId);

    if (stateType === 'RECURRING') {
      updateRecurringTask('dueDate', date, index, taskId, parentIndex, parentId);
    } else saveTasksDueDate(dataType, index, taskId, date, parentIndex, parentId, stateType);
  };

  /*
   * Update due dates
   *
   * index Integer
   * startDate Object
   * endDate String
   *
   * returns null
   */
  const updateStartEndDates = (
    index: number,
    taskId: string,
    startDate: Date | null,
    endDate: Date | null,
    parentIndex?: number,
    parentId?: string,
  ) => {
    if (stateType === 'RECURRING') {
      updateRecurringTask(
        'startAndEndDate',
        startDate || endDate ? [startDate, endDate] : null,
        index,
        taskId,
        parentIndex,
        parentId,
      );
    } else
      saveTasksStartEndDate(
        dataType,
        index,
        taskId,
        startDate,
        endDate,
        parentIndex,
        parentId,
        stateType,
      );
  };
  /*
   * Save task name
   *
   * index Integer
   * item Object
   * dataType String
   * parentIndex Integer
   * parentId Integer
   *
   * returns null
   */
  const saveTaskName = (
    index: number,
    taskId: string,
    value: string,
    name: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    instant: boolean,
    tempId: string,
  ) => {
    // When a new request is going to be issued,
    // the first thing to do is cancel the previous request
    if (stateType === 'RECURRING') {
      updateRecurringTask('name', value, index, taskId, parentIndex, parentId),
        updateRecurringTaskName(
          typeof parentId !== 'undefined' ? parentId : taskId,
          dataType,
          stateType,
        );
    } else if (stateType === 'TEMPLATE') {
      updateTemplateTask(
        name,
        value,
        index,
        taskId,
        parentIndex,
        parentId,
        dataType,
        instant,
        value,
      );
      updateRecurringTaskName(
        typeof parentId !== 'undefined' ? parentId : taskId,
        dataType,
        stateType,
      );
    } else {
      updateTaskName(value, dataType, index, name, parentIndex, parentId, instant, stateType);
    }
  };

  const updateTaskName = (
    value: string,
    dataType: TaskType,
    index: number,
    name: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    instant: boolean | undefined,
    stateType?: StateType,
  ) => {
    saveTaskNameInRecoil(
      value,
      dataType,
      index,
      name,
      parentIndex,
      parentId,
      false,
      instant,
      stateType,
    );
  };

  const updateRecurringTaskName = _.debounce(
    (id: string, dataType: TaskType, stateType: StateType) => {
      updateRecurringTaskNameInRecoil(id, dataType, stateType);
    },
    TASK_INPUT_WAIT_INTERVAL,
    false,
  );

  const addNewTask = (
    index: number,
    parentIndex?: number,
    parentId?: string | null,
    recurring?: boolean,
  ) => {
    if (stateType === 'RECURRING' || (stateType === 'TEMPLATE' && recurring)) {
      if (parentId && parentIndex !== undefined && parentIndex !== null)
        addNewSubtask(
          parentIndex,
          index,
          accountSettings?.defaultPreferredSize
            ? accountSettings?.defaultPreferredSize
            : DEFAULT_SIZE,
          dataType,
          stateType,
        );
    } else
      addTaskInBetween(
        index,
        parentIndex,
        parentId,
        dataType,
        stateType === 'TEMPLATE' ? null : assignDefaultToSelf ? userId : null,
        stateType,
        accountSettings?.defaultPreferredSize
          ? accountSettings?.defaultPreferredSize
          : DEFAULT_SIZE,
      );
  };

  /*
   * Update size
   * returns null
   */
  const updateTaskSize = (
    index: number,
    id: string,
    size: SizeOptionsType,
    parentIndex?: number,
    parentId?: string,
    recurring?: boolean,
  ) => {
    if (stateType === 'RECURRING')
      updateRecurringTask('size', size, index, id, parentIndex, parentId);
    else if (stateType === 'TEMPLATE')
      updateTemplateTask('size', size, index, id, parentIndex, parentId, dataType, false, null);
    else {
      updateSizeGroup(index, id, size, parentIndex, parentId, dataType, stateType);
    }
  };

  const updateTaskCollapseStatus = (
    index: number,
    id: string,
    collapseStatus: boolean,
    parentIndex?: number,
    parentId?: string,
  ) => {
    if (stateType === 'RECURRING')
      updateRecurringTask('collapsedSubTasks', collapseStatus, index, id, parentIndex, parentId);
    else if (stateType === 'TEMPLATE')
      updateTemplateTask(
        'collapsedSubTasks',
        collapseStatus,
        index,
        id,
        parentIndex,
        parentId,
        dataType,
        false,
        null,
      );
    else saveTaskCollapsableState(id, collapseStatus, parentIndex, parentId, dataType, stateType);
  };

  /*
   * Update tag
   *
   * dataType String
   * index Integer
   * newTagId String
   *
   *
   * returns null
   */
  const updateTaskProject = (
    index: number,
    taskId: string,
    newProjectId: string | null,
    parentIndex?: number,
    parentId?: string,
    taskName?: string,
  ) => {
    if (stateType === 'RECURRING') {
      updateRecurringTask(
        'project',
        newProjectId !== null ? { id: newProjectId } : null,
        index,
        taskId,
        parentIndex,
        parentId,
        taskName,
      );
    } else
      saveTasksProject(
        index,
        taskId,
        newProjectId ? newProjectId : null,
        parentIndex,
        parentId,
        dataType,
        stateType,
        taskName,
      );
  };

  /*
   * Update task tags
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const updateTaskTags = (
    index: number,
    taskId: string,
    data: TagObjectType[] | null,
    parentIndex?: number,
    parentId?: string,
    update?: boolean,
    isRecurring?: boolean,
    taskName?: string,
  ) => {
    if (stateType === 'RECURRING')
      updateRecurringTask('tags', data, index, taskId, parentIndex, parentId, taskName);
    else
      updateTaskTagsInRecoil(
        dataType,
        index,
        data,
        parentIndex,
        update ? update : false,
        stateType,
      );
  };

  /*
   * Update assignee
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const updateTaskAssignee = async (
    index: number,
    taskId: string,
    newAssigneeId: string | null,
    parentIndex?: number,
    parentId?: string,
    isRecurring?: boolean,
    taskName?: string,
  ) => {
    if (stateType === 'RECURRING') {
      if (isAdmin())
        updateRecurringTask(
          'assignee',
          newAssigneeId !== null ? { id: newAssigneeId } : null,
          index,
          taskId,
          parentIndex,
          parentId,
          taskName,
        );
    } else if (stateType === 'TEMPLATE') {
      updateTemplateTask(
        'assignee',
        newAssigneeId,
        index,
        taskId,
        parentIndex,
        parentId,
        dataType,
        false,
        taskName ? taskName : null,
      );
    } else
      saveTaskAssignee(
        newAssigneeId ? newAssigneeId : '-1',
        dataType,
        index,
        taskId,
        parentIndex,
        parentId,
        taskName,
        stateType,
      );
  };

  /*
   * Delete task
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const deleteTask = (
    index: number,
    id: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    recurring: boolean,
    checked: boolean,
  ) => {
    if (stateType === 'RECURRING') {
      deleteRecurringTask(index, parentIndex, dataType, stateType);
    } else if (stateType === 'TEMPLATE' && recurring) {
      deleteTemplateRecurringTask(index, id, dataType, parentIndex, parentId, stateType);
    } else {
      if (checked) {
        deleteItemWithDebounce(index, id, dataType, parentIndex, parentId, stateType);
      } else {
        deleteItem(index, id, dataType, parentIndex, parentId, stateType);
      }
    }
  };

  const deleteItem = (
    index: number,
    id: string,
    dataType: TaskType,
    parentIndex: number | undefined,
    parentId?: string,
    stateType?: StateType,
  ) => {
    log('deletePressed 1');
    deleteTaskRecoil(index, id, dataType, parentIndex, parentId, stateType);
  };

  const deleteItemWithDebounce = _.debounce(
    (
      index: number,
      id: string,
      dataType: TaskType,
      parentIndex: number | undefined,
      parentId?: string,
      stateType?: StateType,
    ) => deleteTaskRecoil(index, id, dataType, parentIndex, parentId, stateType),
    TASK_INPUT_WAIT_INTERVAL,
    false,
  );

  /*
   * Check task
   *
   * index Integer
   * id Integer
   * dataType String
   * parentIndex Integer
   * parentId Integer
   *
   *
   * returns null
   */

  const checkTask = (
    index: number,
    id: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    checked: boolean,
  ) => {
    log('checkTask', index, id, parentIndex, parentId, checked);
    if (stateType === 'RECURRING') {
      checkRecurringTask(index, parentIndex, checked);
    } else {
      if (readOnly) {
        if (!checked) unCheckTask(index, id, parentIndex, parentId, false, stateType);
      } else {
        checkTasks(index, id, dataType, parentIndex, parentId, stateType, checked);
      }
    }
  };

  useEffect(() => {
    log('setHighlightedTask TaskItems');
    if (tasks.data === null) return;
    log('setHighlightedTask TaskItems 1');
    if (currentHighlightedTask === null) {
      for (let i = 0; i < tasks.data.length; i++) {
        if (!itemShouldBeFilteredOut(tasks.data[i])) {
          log('setHighlightedTask TaskItems 2');
          setHighlightedTask(tasks.data[i], stateType);
          break;
        }
      }
    }
  }, [tasks.data]);
  const itemProps = {
    storeDateRefs,
    onChangeDate,
    onChangeDueDate,
    stateType,
    dataType,
    readOnly,
    storeInputRefs,
    updateTaskProject,
    updateTaskAssignee,
    saveTaskName,
    updateTaskSize,
    addNewTask,
    checkTask,
    updateTaskTags,
    deleteTask,
    updateTaskCollapseStatus,
  };

  const itemShouldBeFilteredOut = useCallback((_item) => {
    if (readOnly) return false;

    if (!accountSettings.filterVisiblity) return false;

    return false;
  }, []);

  const itemDoesNotHaveRequiredDate = (dueDate: Moment | Date | null) => {
    if (!dateFilter) return false;

    if (!dueDate) return true;

    dueDate = moment(dueDate);
    if (!dueDate.isValid()) return true;

    let current = moment(currentDay());

    switch (dateFilter) {
      case 'yesterday': {
        const diff = dueDate.startOf('day').diff(current.startOf('day'), 'days', true);

        if (diff !== -1) return true;

        return false;
      }
      case 'today': {
        const same = dueDate.startOf('day').isSame(current.startOf('day'), 'day');

        if (!same) return true;

        return false;
      }
      case 'currentMonth': {
        const same = dueDate.startOf('day').isSame(current.startOf('day'), 'month');

        if (!same) return true;

        return false;
      }
      case 'lastMonth': {
        current = current.subtract(1, 'months');

        const same = dueDate.startOf('day').isSame(current.startOf('day'), 'month');

        if (!same) return true;

        return false;
      }
      case 'yearToDate': {
        const sameYear = dueDate.startOf('day').isSame(current.startOf('day'), 'year');

        const diff = dueDate.startOf('day').diff(current.startOf('day'), 'day');

        if (!sameYear || diff >= 1) return true;

        return false;
      }
      case 'previousYear': {
        current = current.subtract(1, 'years');

        const same = dueDate.startOf('day').isSame(current.startOf('day'), 'year');

        if (!same) return true;

        return false;
      }
      default: {
        const dateInFilter = moment(dateFilter, 'MM-DD-YYYY');

        if (!dateInFilter.isValid()) return false;

        const same = dueDate.startOf('day').isSame(dateInFilter.startOf('day'), 'day');

        if (!same) return true;
      }
    }

    return false;
  };

  const itemDoesNotHaveRequiredSize = (size: SizeOptionsType) => {
    if (!sizeFilter) return false;

    if (size !== sizeFilter) return true;

    return false;
  };

  const itemDoesNotHaveRequiredAssignee = (assigneeId: string) => {
    if (!assigneeFilter) return false;

    if (assigneeId === undefined || assigneeId === null) assigneeId = 'UNASSIGNED';

    if (!assigneeFilter.includes(assigneeId)) return true;
    // if (assigneeId !== assigneeFilter) return true;

    return false;
  };

  const itemDoesNotHaveRequiredProject = (projectId: string) => {
    if (!projectFilter) return false;

    if (!projectFilter.includes(projectId)) return true;

    // if (projectId !== projectFilter) return true;

    return false;
  };

  const itemDoesNotHaveRequiredTag = (tags: TagObjectType[]) => {
    if (!tagFilter) return false;

    let hide = true;

    for (let i = 0; i < tags.length; i++) {
      const tagId = tags[i].id;

      if (tagFilter.includes(tagId)) {
        hide = false;
        break;
      }
    }

    return hide;
  };

  const isAssignedUserForTasks = (item: TaskObjectType) => {
    if (
      ((stateType === null || stateType === undefined) && item?.assignee?.id !== userId) ||
      (stateType === 'INDIVIDUAL' && item?.assignee?.id !== userData.currentUserId)
    ) {
      return false;
    }
    return true;
  };

  const getTaskCount = useMemo(() => {
    let taskCount = 0;
    tasks.data &&
      tasks.data.forEach((item) => {
        if (typeof item !== 'undefined' && !itemShouldBeFilteredOut(item)) {
          taskCount++;
        }

        if (typeof item !== 'undefined' && item.subTasks && item.subTasks.length) {
          item.subTasks.forEach((subItem) => {
            if (!subItem.checked && !itemShouldBeFilteredOut(subItem)) {
              taskCount++;
            }
          });
        }
      });

    return taskCount;
  }, [
    JSON.stringify(tasks.data),
    dateFilter,
    sizeFilter,
    assigneeFilter,
    projectFilter,
    tagFilter,
  ]);

  const getPoints = useMemo(() => {
    let points = 0;
    tasks.data &&
      tasks.data.forEach((item) => {
        if (
          typeof item !== 'undefined' &&
          item.size &&
          isAssignedUserForTasks(item) &&
          !itemShouldBeFilteredOut(item) &&
          allSizes[item.size] &&
          allSizes[item.size].points
        )
          points += allSizes[item.size].points;

        if (typeof item !== 'undefined' && item.subTasks && item.subTasks.length) {
          item.subTasks &&
            item.subTasks.forEach((subItem) => {
              if (
                subItem.size &&
                isAssignedUserForTasks(subItem) &&
                !subItem.checked &&
                !itemShouldBeFilteredOut(subItem) &&
                allSizes[subItem.size] &&
                allSizes[subItem.size].points
              )
                points += allSizes[subItem.size].points;
            });
        }
      });

    return points;
  }, [
    JSON.stringify(tasks.data),
    dateFilter,
    sizeFilter,
    assigneeFilter,
    projectFilter,
    tagFilter,
  ]);

  const getAnimationName = () => {
    return filenames[Math.floor(Math.random() * filenames.length)];
  };

  const getAnimationClass = (item: TaskObjectType, itemIndex: number) => {
    const animation: TriggersType | null =
      item.animation === 'ADD_TASK' || item.animation === 'ADD_TASK_NEW'
        ? 'new-task-creation'
        : item.animation === 'COMPLETE_TASK'
          ? 'end-task-complete'
          : null;

    if (animation === 'new-task-creation') {
      const index = animations.findIndex(
        (anim) => anim.type === animation && item.tempId === anim.tempId,
      );

      if (index !== -1) return null;
      else {
        setTimeout(() => {
          const tempAnimations = animations;
          if (animation) tempAnimations.push({ type: animation, tempId: item.tempId });
          setAnimations(tempAnimations);

          if (item.animation === 'ADD_TASK_NEW') resetTaskAnimation(itemIndex);
        }, triggerData.createTaskDelay);

        if (item.animation === 'ADD_TASK_NEW') {
          taskCreatedAnimation(item.tempId);
        }
      }
    }

    return animation;
  };

  const resetTaskAnimation = (index: number) => {
    updateTaskAnimation(dataType, index, 'ADD_TASK', undefined, stateType);
  };

  const taskCreatedAnimation = (tempId) => {
    setTimeout(() => {
      if (triggerData.canvasTriggers.createTask) {
        runCanvasAnimation(triggerData.canvasTriggers.createTask, { tempId });
      }
    }, 2);
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (event: KeyboardEvent | MouseEvent) => {
    return isMacOS() ? event.metaKey : event.ctrlKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event) => event.shiftKey;

  const performAction = (
    event: KeyboardEvent | MouseEvent,
    id: string,
    tempId: string,
    parentId?: string,
  ) => {
    if (
      (event?.target &&
        event.target instanceof Element &&
        (event.target.tagName === 'svg' ||
          (event?.target?.className &&
            typeof event?.target?.className === 'string' &&
            (event?.target?.className?.includes('item-btn') ||
              event?.target?.className?.includes('item-selected') ||
              event?.target?.className?.includes('szh-menu__item') ||
              event?.target?.className?.includes('assignee-image ') ||
              event?.target?.className?.includes('project-name-num') ||
              event?.target?.className?.includes('project-name-text') ||
              event?.target?.className?.includes('checkbox-svg-con') ||
              event?.target?.className?.includes('tag-text') ||
              event?.target?.className?.includes('update-date-item') ||
              event?.target?.className?.includes('react-datepicker') ||
              event?.target?.className?.includes('close-icon'))))) ||
      readOnly
    ) {
      return;
    }

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      log('toggleSelection', selectedTasks);

      toggleSelectionInGroup(id, tempId, parentId);

      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(id, tempId, parentId);
      return;
    }

    toggleSelection(id, tempId, parentId);
  };

  const onKeyDown = (
    event: KeyboardEvent,
    snapshot: any,
    id: string,
    tempId: string,
    parentId: string | null = null,
  ) => {
    // already used
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.key !== 'Enter') {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    performAction(event, id, tempId, parentId ? parentId : undefined);
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClickTask = (
    event: MouseEvent,
    id: string,
    tempId: string,
    parentId: string | undefined | null = null,
  ) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event, id, tempId, parentId ? parentId : undefined);
  };

  const renderItems = useMemo(() => {
    if (isLoading && !readOnly)
      return (
        <div className='loader-con' id='loader-con'>
          <Spinner />
        </div>
      );

    return [
      tasks.data && (
        <div className={`task-container ${tasks.data.length > 300 ? 'scroll' : ''}`} ref={ref}>
          <ViewportList viewportRef={ref} items={tasks.data} overflowAnchor='none'>
            {(item, index) => {
              const isSelected = selectedTaskIds.some(
                (selectedTaskId) => selectedTaskId.id === item.tempId,
              );
              const isGhosting =
                isSelected && Boolean(draggingTaskId) && draggingTaskId !== item.tempId;
              const isMultiSelected =
                selectedTasks &&
                selectedTasks.find((e) => e.id === item.id) !== undefined &&
                selectedTasks.find((e) => e.id === item.id) !== null;
              if (itemShouldBeFilteredOut(item)) return null;
              else {
                return (
                  <Draggable
                    key={item.tempId}
                    draggableId={item.tempId + '' + dataType}
                    dataType={dataType}
                    index={index}
                    isDragDisabled={readOnly || stateType === 'RECURRING'}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div key={item.tempId} ref={provided.innerRef} {...provided.draggableProps}>
                          <div
                            id={item.id}
                            className={`${getAnimationClass(item, index)} task-main-con ${
                              isMultiSelected ? 'multi-select-main' : ''
                            } ${isGhosting ? 'row-ghosting' : ''}`}
                            onClick={(e) => onClickTask(e, item.id, item.tempId)}
                            onKeyDown={(event) => onKeyDown(event, snapshot, item.id, item.tempId)}
                          >
                            {(item.animation === 'ADD_TASK' || item.animation === 'ADD_TASK_NEW') &&
                              triggerData.riveTriggers.createTask &&
                              canBePlayed(triggerData.riveTriggers.createTask) && (
                                <Animations
                                  fileName={getAnimationName()}
                                  className='rive-task-main'
                                  // shouldTrigger={playRiveAnimation.createTaskAnimation}
                                />
                              )}
                            <span className='new-top-obj'></span>
                            <div className='new-border-second-box'></div>
                            <IndividualItem
                              {...itemProps}
                              hasSubtasks={item.subTasks && item.subTasks.length ? true : false}
                              isDragging={snapshot.isDragging}
                              provided={provided}
                              index={index}
                              itemId={item.id}
                              isTemp={item.isTemp}
                              tempId={item.tempId}
                              key={item.tempId + index}
                              item={item}
                              itemProject={item.project}
                              itemSize={item.size ? item.size : 'M'}
                              itemChecked={item.checked}
                              itemNumber={item.taskNumber}
                              itemStartDate={item.startDate ? item.startDate : undefined}
                              itemEndDate={item.endDate ? item.endDate : undefined}
                              itemDueDate={item.dueDate ? item.dueDate : undefined}
                              itemAssignee={item.assignee}
                              updateValue={item.updateValue}
                              itemComments={item.comments ? item.comments : []}
                              animation={item.animation}
                              recurringTaskMeta={item.recurringTaskMeta}
                              itemTemplate={templateData.currentTemplateId}
                              collapsedSubTasks={item.collapsedSubTasks}
                              selectedCount={selectedTaskIds.length}
                              isMultiSelected={isMultiSelected}
                              bulkUpdateTasksTag={bulkUpdateTasksTag}
                              hideDragIcon={hideDragIcon}
                              isHighlighted={
                                currentHighlightedTask && currentHighlightedTask.id === item.id
                              }
                              selectedNotificationTaskId={selectedNotificationTaskId}
                            />
                            <Collapse isOpened={!item.collapsedSubTasks}>
                              {renderSubTasks(
                                item.subTasks,
                                index,
                                item.id,
                                item.tempId,
                                isGhosting,
                              )}
                            </Collapse>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              }
            }}
          </ViewportList>
        </div>
      ),
    ];
  }, [
    JSON.stringify(tasks.data),
    isLoading,
    readOnly,
    dateFilter,
    sizeFilter,
    assigneeFilter,
    projectFilter,
    tagFilter,
    JSON.stringify(selectedTasks),
    draggingTaskId,
    JSON.stringify(selectedTaskIds),
    hideDragIcon,
    selectedNotificationTaskId,
  ]);

  const styledDroppable = (subtask: number) => ({
    padding: !subtask ? 1 : 0,
  });

  const renderSubTasks = (
    subTasks: TaskObjectType[] | undefined,
    parentIndex: number,
    parentId: string,
    parentTempId: string,
    isGhosting: boolean,
  ) => {
    const droppableId = dataType + '-' + parentId;
    const subTaskProps = { ...itemProps, parentIndex, parentId, parentTempId };

    return (
      <div className='subtasks-container' style={{ marginLeft: '44px', display: 'block' }}>
        <Droppable
          droppableId={droppableId}
          type={`droppableSubItem`}
          key={dataType + parentTempId}
        >
          {(dropProvided, _snapshot) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
              {...dropProvided.dragHandleProps}
              className='subtasks-con-droppable'
              style={styledDroppable(subTasks ? subTasks.length : 0)}
            >
              {subTasks &&
                subTasks.map((subItem, subIndex) => {
                  if (!subItem) return;
                  if (itemShouldBeFilteredOut(subItem)) return null;
                  else
                    return (
                      <Draggable
                        key={subItem.tempId}
                        draggableId={subItem.tempId + '' + dataType}
                        dataType={dataType}
                        index={subIndex}
                        isDragDisabled={readOnly}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              id={subItem.id}
                              key={subItem.tempId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='subtasks-con'
                              onClick={(e) => onClickTask(e, subItem.id, subItem.tempId, parentId)}
                              onKeyDown={(event) =>
                                onKeyDown(event, snapshot, subItem.id, subItem.tempId, parentId)
                              }
                            >
                              <IndividualItem
                                {...subTaskProps}
                                isDragging={snapshot.isDragging}
                                provided={provided}
                                key={subItem.tempId + parentIndex}
                                index={subIndex}
                                itemId={subItem.id}
                                isTemp={subItem.isTemp}
                                tempId={subItem.tempId}
                                item={subItem}
                                itemProject={subItem.project}
                                itemSize={subItem.size ? subItem.size : 'M'}
                                itemChecked={subItem.checked}
                                itemNumber={subItem.taskNumber}
                                itemStartDate={subItem.startDate}
                                itemEndDate={subItem.endDate}
                                itemDueDate={subItem.dueDate}
                                itemAssignee={subItem.assignee}
                                updateValue={subItem.updateValue}
                                itemComments={subItem.comments ? subItem.comments : []}
                                animation={subItem.animation}
                                itemTemplate={templateData.currentTemplateId}
                                selectedCount={selectedTasks.length}
                                isMultiSelected={
                                  selectedTasks &&
                                  selectedTasks.find((e) => e.id === subItem.id) !== null &&
                                  selectedTasks.find((e) => e.id === subItem.id) !== undefined
                                }
                                hideDragIcon={hideDragIcon}
                                isHighlighted={
                                  currentHighlightedTask && currentHighlightedTask.id === subItem.id
                                }
                                bulkUpdateTasksTag={bulkUpdateTasksTag}
                                selectedNotificationTaskId={selectedNotificationTaskId}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                })}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  // log('render components/TaskItems');

  /**
   * render the point for all tasks in list
   * @returns DOM element
   */
  const renderPoints = () => {
    return (
      <span className='points-con' id='points-con'>
        <span
          className='points-in'
          id='points-in'
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-focus-point`}
          data-tooltip-content={`You have ${getTaskCount} tasks`}
        >
          {readOnly ? getCompletedPoints : getPoints}
        </span>
        Points
      </span>
    );
  };

  return (
    <div className='task-list-con' id='task-list-con'>
      <h4 key='title' className='task-title' id='task-title'>
        {title} {renderPoints()}
        <Tooltip className='tool-tip' id={`tool-tip-focus-point`} />
      </h4>

      <Droppable droppableId={dataType} type='droppableItem' key={dataType}>
        {(provided, _snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
            className='task-list-title'
          >
            <div className='tasks-in-con' id='tasks-in-con'>
              {renderItems}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default memo(TaskItems);
