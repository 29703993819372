import { randomNumber, sortData } from 'utils/helpers';
import { log } from 'utils';
import { createUserInitials } from 'utils/user';
import moment, { Moment } from 'moment';
import { sortStringData } from './helpers';
import { setItem, removeItem } from './storage';
import { addTaskData } from 'recoil/TaskState/update';
import { getUserId } from 'services/AuthService';

export const SEQUENCE_LARGE = 500;
export const SEQUENCE_SMALL = 100;

export const SIZE_TRIGGER = '$';
export const TAG_TRIGGER = '#';
export const ASSIGNEE_TRIGGER = '@';
export const RECURRING_KEY = '%';

export const UP_KEY_CODE = 'ArrowUp'; // 38;
export const DOWN_KEY_CODE = 'ArrowDown'; // 40;
export const ENTER_KEY_CODE = 'Enter';
export const TAB_KEY_CODE = 'Tab'; // 9;
export const BACKSPACE_KEY_CODE = 'Backspace'; // 8;
// export const DELETE_KEY_CODE = 8;

export const weekDays = [
  {
    id: 'Sunday',
    name: 'Su',
  },
  {
    id: 'Monday',
    name: 'M',
  },
  {
    id: 'Tuesday',
    name: 'T',
  },
  {
    id: 'Wednesday',
    name: 'W',
  },
  {
    id: 'Thursday',
    name: 'Th',
  },
  {
    id: 'Friday',
    name: 'F',
  },
  {
    id: 'Saturday',
    name: 'Sa',
  },
];

export const filterPrefix = 'magic_task';

export const getOtherDataType = (dataType: TaskType): TaskType => {
  if (dataType === 'FOCUS') return 'BACKLOG';
  return 'FOCUS';
};

export const removeTriggersFromValue = (value: string) => {
  value = value.replace(/\B\$\w+/g, '');
  value = value.replace(/\B#\w+/g, '');
  value = value.replace(/\B@\w+/g, '');
  return value;
};

export const isAdminScreen = (name: string) => {
  if (name.includes('/admin')) return true;

  return false;
};

export const isProjectTaskScreen = (name: string) => {
  if (name === '/project/:projectId') return true;

  return false;
};

export const isUserScreen = (name: string) => {
  if (name === '/user/:userId') return true;

  return false;
};

export const isUserCompletedScreen = (name: string) => {
  if (name === '/user/:userId/completed') return true;

  return false;
};

export const isProjectCompletedScreen = (name: string) => {
  if (name === '/project/:projectId/completed') return true;

  return false;
};

export const isHomeScreen = (name: string) => {
  if (name === '/') return true;

  return false;
};

export const isRecurringTasksScreen = (name: string) => {
  if (name === '/recurring-tasks') return true;

  return false;
};

export const isMeetingsScreen = (name: string) => {
  if (name === '/meetings') return true;

  return false;
};

export const isHomeCompletedScreen = (name: string) => {
  if (name === '/completed') return true;

  return false;
};

export const isMeetingDetailsScreen = (name: string) => {
  if (name === '/meeting-details/:meetingId') return true;

  return false;
};

export const isManageWorkspacesScreen = (name: string) => {
  if (name === '/super-admin/workspaces') return true;

  return false;
};

export const isSuperAdminAnalyticsScreen = (name: string) => {
  if (name === '/super-admin/analytics') return true;

  return false;
};

export const isWorkspaceDetailScreen = (name) => {
  if (name === '/super-admin/workspace/:workspaceName/:workspaceId') return true;

  return false;
};

export const isThemeListingScreen = (name: string) => {
  if (name === '/super-admin/themes') return true;
  return false;
};

export const isCreateThemeScreen = (name: string) => {
  if (name === '/super-admin/create-theme') return true;
  return false;
};

export const isWhatsNewScreen = (name: string) => {
  if (name === '/super-admin/whats-new') return true;
  return false;
};

export const isThemesOrShopScreen = (name: string) => {
  if (name === '/shop' || name === '/theme') return true;

  return false;
};

export const isThemesScreen = (name: string) => {
  if (name === '/super-admin/themes') return true;
  return false;
};

export const isTutorialsScreen = (name: string) => {
  if (name === '/user-tutorial') return true;

  return false;
};

export const isTutorialCompletedScreen = (name: string) => {
  if (name === '/completed/user-tutorial') return true;

  return false;
};

export const isTutorialProjectCompletedScreen = (name: string) => {
  if (name === '/:projectId/completed') return true;

  return false;
};

export const isProjectTemplatesListScreen = () => {
  if (window.location.pathname === '/admin/project-templates') return true;

  return false;
};

export const isProjectTemplateScreen = (name: string) => {
  if (name === '/project-templates/:templateId') return true;

  return false;
};

export const parseTask = (
  task: ResponseTaskInterface,
  stateType?: StateType | null,
  readOnly?: boolean,
  stringDate?: boolean,
) => {
  log(task, 'parseTask');

  const data = parseTasksIn(task, stateType, stringDate);

  return {
    ...data,
    subTasks: task.subTasks ? parseSubTasks(task.subTasks, stateType, readOnly, stringDate) : [],
  };
};

export const getName = (data: ResponseUserType) => {
  let userName = '';
  if (data?.firstName) {
    userName = userName + data.firstName;
  }
  if (data?.lastName) {
    if (userName.length > 0) {
      userName = userName + ' ';
    }
    userName = userName + data.lastName;
  }

  if (userName.length <= 0 && data.email) {
    userName = data.email;
  }
  return userName;
};

export const currentDay = () => {
  const date = new Date();
  return new (moment as any)(date).utc(false).toDate();
};

export const parseToDateObject = (date: Date) => {
  const dateString = `${date[0]}-${date[1]}-${date[2]}`;
  return new (moment as any)(dateString, 'YYYY-MM-DD').utc(false).toDate();
};

export const parseToDateObjectFromString = (date: string | Date, format?: string) => {
  if (!date) return null;

  const dateFormat = format ? format : 'YYYY-MM-DD';

  return new (moment as any)(date, dateFormat).utc(false).toDate();
};

export const parseToDateObjectFromStringAsUTC = (date: string | number | Date, format?: string) => {
  if (!date) return null;

  const dateFormat = format ? format : 'YYYY-MM-DD';

  return new (moment as any)(date, dateFormat).utc(true).toDate();
};

export const parseToDateObjectFromTimestamp = (date: number | Date | string) => {
  return new (moment as any)(date).utc(false).toDate();
};

export const formatDateForView = (date: string | number | Date | undefined, format?: string) => {
  if (!date) return '';

  const dateFormat = format ? format : 'MM-DD-YYYY';

  return moment(date).format(dateFormat);
};

export const formatDateForMeeting = (date: EpochTimeStamp | string | Date) => {
  if (!date) return '';
  return moment(date).format('MM-DD-YYYY | hh:mm a');
};

export const formatDateForTaskInfo = (date: Date | number) => {
  if (!date) return '';
  return moment(date).format('MM-DD-YYYY hh:mma');
};

export const formatDateForUsersView = (date: string | Date) => {
  if (!date) return '';
  return moment(date).format('MM-DD-YYYY');
};

export const formatDateWithYearForView = (date: string) => {
  return moment(date).format('MMM D YYYY');
};

export const formatDateForAPI = (date: Date | Moment) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatTimeForAPI = (date: Date | number | string) => {
  return moment(date).format('hh:mm a');
};

export const formatTimeToUTC = (date: number | Date | string) => {
  return moment.utc(date).format('hh:mm a');
};

export const formatTimeForThemeAPI = (date: string) => {
  return moment(date).format('HH:mm:ssZ');
};

export const formatForAnalytics = (date: Moment | Date) => {
  return moment.utc(date).format('yyyy-MM-DDTHH:mm:ssZ');
  // return new (moment as any)(date, 'yyyy-MM-DDTHH:mm:ssZ').toDate();
};

export const parseTasksIn = (
  task: ResponseTaskInterface | ResponseRecurringTaskInterface,
  stateType?: StateType | null,
  stringDate?: boolean,
) => {
  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE' ? task.projecttaskType : task.taskType;

  return {
    id: task.id ? task.id : null,
    comments: task.comments ? parseTaskComments(task.comments, task.project, taskType) : [],
    sequence:
      stateType === 'PROJECT' || stateType === 'TEMPLATE'
        ? task.projectSequence
        : task.sequence
          ? task.sequence
          : 0,
    tempId: randomNumber() + '',
    name: task.name,
    tempName: task.name,
    taskNumber: task.taskNumber ? task.taskNumber : 0,
    createdUser: task.createdUser
      ? task.createdUser
      : task.createdBy
        ? getName(task.createdBy)
        : '',
    createdDate: task.created
      ? parseToDateObjectFromStringAsUTC(task.created, 'MMM D, YYYY, hh:mm:ss A')
      : null,
    modifiedUser: task.modifiedBy ? getName(task.modifiedBy) : '',
    modifiedDate: task.modified
      ? parseToDateObjectFromStringAsUTC(task.modified, 'MMM D, YYYY, hh:mm:ss A')
      : null,
    points: task.points,
    size: task.size ? task.size : null,
    project: task.project,
    projectId: task.projectId, // for templates this is used as tag is null but this param contains templateId
    tags: task.tags ? parseTags(task.tags) : [],
    tagInfo: task.tagInfo,
    assignee: task.assignee
      ? {
          id: task.assignee.id,
          name: getName(task.assignee),
          profileImage: task.assignee.profileImage,
        }
      : null,
    checked: task.status && task.status === 'OPEN' ? false : task.recurring ? false : true,
    taskType: taskType,
    startDate:
      task.startDate && task.endDate
        ? stringDate
          ? parseToDateObjectFromString(task.startDate)
          : parseToDateObject(task.startDate)
        : null,
    endDate:
      task.startDate && task.endDate
        ? stringDate
          ? parseToDateObjectFromString(task.endDate)
          : parseToDateObject(task.endDate)
        : null,
    dueDate: task.dueDate
      ? stringDate
        ? parseToDateObjectFromString(task.dueDate)
        : parseToDateObject(task.dueDate)
      : null,
    abortController: new AbortController(),
    animation: 'ADD_TASK',
    recurringTaskMeta: task.recurringTask
      ? parseRecurringTaskMeta(task.recurringTask, task.created, task.modified, task.createdBy)
      : task.recurring
        ? parseRecurringTaskMeta(task, task.created, task.modified, task.createdBy)
        : null,
    collapsedSubTasks:
      stateType === 'PROJECT'
        ? task.collapsedProjectSubTasks
        : task.collapsedSubTasks
          ? task.collapsedSubTasks
          : false,
    sizeController: new AbortController(),
    assigneeController: new AbortController(),
    projectController: new AbortController(),
    dueDateController: new AbortController(),
    startEndDateController: new AbortController(),
    collapseController: new AbortController(),
    sequenceController: new AbortController(),
    updateController: new AbortController(),
  } as TaskObjectType;
};

export const parseSubTask = (
  task: ResponseTaskInterface,
  stateType?: StateType | null,
  readOnly?: boolean,
  stringDate?: boolean,
) => {
  // log(task, "parseSubTask");

  const data = parseTasksIn(task, stateType, stringDate);

  if (readOnly) data.hidden = data.checked ? false : true;

  return data;
};

/// Returns the difference (in full days) between the provided date and today.
export const getCurrentDateTime = (date: string) => {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  if (moment(date).isSame(today, 'd')) return 'Today';
  else if (moment(date).isSame(yesterday, 'd')) return 'Yesterday';
  else return formatDateForUsersView(date);
};

const parseSubTasks = (
  data: ResponseTaskInterface[],
  stateType?: StateType | null,
  readOnly?: boolean,
  stringDate?: boolean,
) => {
  const newData: TaskObjectType[] = [];
  data.forEach((dat) => {
    if (dat && dat.id) newData.push(parseSubTask(dat, stateType, readOnly, stringDate));
  });

  return sortData(newData, 'sequence');
};

export const createNewTask = (
  value: string,
  tempId: string,
  sequence: number,
  taskType: TaskType,
  projectId: string | null,
  sizeId: SizeOptionsType,
  assigneeId: string | null,
  stateType: StateType | null,
) => {
  log(
    'createNewTask90',
    value,
    tempId,
    sequence,
    taskType,
    projectId,
    sizeId,
    assigneeId,
    stateType,
  );

  return {
    id: tempId,
    tempId: tempId,
    comments: [],
    isTemp: true,
    name: value,
    tempName: value,
    sequence: sequence,
    size: sizeId ? sizeId : null,
    project: projectId ? { id: projectId } : null,
    tags: [],
    assignee: assigneeId ? { id: assigneeId } : null,
    subTasks: [],
    checked: false,
    startDate: null,
    endDate: null,
    dueDate: null,
    createdUser: '',
    taskNumber: 0,
    createdDate: null,
    points: 0,
    taskType: taskType.toUpperCase(),
    sizeController: new AbortController(),
    projectController: new AbortController(),
    assigneeController: new AbortController(),
    updateController: new AbortController(),
    sequenceController: new AbortController(),
    startEndDateController: new AbortController(),
    dueDateController: new AbortController(),
    abortController: new AbortController(),
    collapseController: new AbortController(),
  } as TaskObjectType;
};

export const reorderTasks = (data: TaskObjectType[], startIndex: number, endIndex: number) => {
  const tempData = [...data];
  const [removed] = tempData.splice(startIndex, 1);
  tempData.splice(endIndex, 0, removed);
  return tempData;
};

export const moveSubTasks = (
  sourceData: TaskObjectType[],
  destData: TaskObjectType[],
  startIndex: number,
  endIndex: number,
) => {
  const sourceClone = [...sourceData];
  const destClone = [...destData];
  const [removed] = sourceClone.splice(startIndex, 1);

  destClone.splice(endIndex, 0, removed);

  return {
    source: sourceClone,
    destination: destClone,
  };
};

export const removeFromOneAddToAnother = <T>(
  data: Array<T>,
  dataFrom: Array<T>,
  startIndex: number,
  endIndex: number,
) => {
  const tempDataFrom = [...dataFrom];
  const tempData = [...data];
  const [removed] = tempDataFrom.splice(startIndex, 1);
  tempData.splice(endIndex, 0, removed);
  return { tempData, tempDataFrom };
};

export const bulkRemoveFromOneAddToAnother = (
  data: TaskObjectType[],
  dataFrom: TaskObjectType[],
  endIndex: number,
  fromDataType: TaskType,
  selectedFocus: SelectedTaskIdsType[],
  selectedBacklog: SelectedTaskIdsType[],
  stateType?: StateType,
) => {
  const tempData = [...data];
  const tempDataFrom = [...dataFrom];
  let toAdd: TaskObjectType[] = [];
  if (fromDataType === 'FOCUS') {
    selectedFocus.forEach((item) => {
      const index = tempDataFrom.findIndex((x) => x.id === item.id);
      if (index >= 0) toAdd = [...toAdd, ...tempDataFrom.splice(index, 1)];
    });
    selectedBacklog.forEach((item) => {
      const index = tempData.findIndex((x) => x.id === item.id);
      if (index >= 0) toAdd = [...toAdd, ...tempData.splice(index, 1)];
    });
  } else {
    selectedBacklog.forEach((item) => {
      const index = tempDataFrom.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        toAdd = [...toAdd, ...tempDataFrom.splice(index, 1)];
        log('toAdd', toAdd);
      }
    });
    selectedFocus.forEach((item) => {
      const index = tempData.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        toAdd = [...toAdd, ...tempData.splice(index, 1)];
        log('toAdd', toAdd);
      }
    });
  }
  addTaskData(fromDataType, tempDataFrom, stateType);
  log('bulkRemoveFromOneAddToAnother', data, dataFrom);
  tempData.splice(endIndex, 0, ...toAdd);
  return [tempData, toAdd];
};
export const bulkRemoveAddInSame = (
  data: TaskObjectType[],
  endIndex: number,
  selectedTasks: SelectedTaskIdsType[],
) => {
  let tempData = [...data];
  let toAdd: TaskObjectType[] = [];
  selectedTasks.forEach((item) => {
    const index = tempData.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      toAdd = [...toAdd, ...tempData.filter((item, idx) => idx === index)];
      tempData = [...tempData.filter((item, idx) => idx !== index)];

      if (index < endIndex) {
        endIndex = endIndex - 1;
      }
    }
  });

  log('bulkRemoveAddInSame', tempData);
  tempData.splice(endIndex, 0, ...toAdd);
  return [tempData, toAdd, endIndex];
};

export const getNewSequence = (index: number, data: TaskObjectType[]) => {
  if (data.length === 1) {
    return SEQUENCE_LARGE;
  } else if (index >= data.length - 1) {
    log('added at end of list');
    return data[data.length - 2].sequence - SEQUENCE_SMALL;
  } else if (index === 0) {
    log('added at start of list');
    return data[index + 1].sequence + SEQUENCE_SMALL;
  } else {
    log('added at middle of list');
    const topItemSequence = data[index - 1].sequence;
    const bottomItemSequence = data[index + 1].sequence;
    const difference = topItemSequence - bottomItemSequence;

    if (difference === 0) return topItemSequence;

    const middle = difference / 2;
    const roundOffValue = Math.round(middle * 100000 + Number.EPSILON) / 100000;

    return bottomItemSequence + roundOffValue;
  }
};

export const isArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const parseProjectsForMentions = (
  data: ResponseProjectType[],
  folderId?: string,
  folderName?: string,
) => {
  const newData: ProjectObjectType[] = [];

  isArray(data) &&
    data.forEach((dat) => {
      const project = dat.project;

      if (project && project.id) {
        const field: ProjectObjectType = {
          id: project.id,
          display: project.name,
          name: project.name,
          users: project.users ? parseUsers(project.users) : [],
          createdBy: project.createdBy,
          formId: project.formId ? project.formId : undefined,
          openCount: dat.openCount ? dat.openCount : 0,
          completedCount: dat.recentCompletedCount ? dat.recentCompletedCount : 0,
          focusPoints: dat.focusPoints ? dat.focusPoints : 0,
          backlogPoints: dat.backlogPoints ? dat.backlogPoints : 0,
          startDate: dat.startDate ? parseToDateObjectFromString(dat.startDate) : null,
          dueDate: dat.dueDate ? parseToDateObjectFromString(dat.dueDate) : null,
          endDate: dat.endDate ? parseToDateObjectFromString(dat.endDate) : null,
          status: dat.status ? dat.status : '',
          archived: project.archived ? project.archived : false,
          isOpen: false,
          remainingPoints: dat.remainingPoints,
          modified: project.modified ? parseToDateObjectFromTimestamp(project.modified) : null,
          sequence: project.sequence ? project.sequence : 0,
          completedPoints: dat.completedPoints,
          folder:
            folderId !== null && folderId !== undefined ? { id: folderId, name: folderName } : null,
        };
        newData.push(field);
      }
    });

  return sortData(newData, 'sequence', true);
};

export const parseUsersWithCount = (data: ResponseUserCountsType[]): UserCountsType[] => {
  const newData: UserCountsType[] = [];
  isArray(data) &&
    data.forEach((dat) => {
      if (dat.user.id !== getUserId()) {
        if (dat.user && dat.user.id) {
          const field = parseUserWithCount(dat);
          newData.push(field);
        }
      }
    });

  return newData;
};

export const parseUserWithCount = (dat: ResponseUserCountsType): UserCountsType => {
  const field: UserCountsType = {
    id: dat.userId,
    user: parseUser(dat.user),
    openTasks: dat.openTasks,
    openParentTasks: dat.openParentTasks,
    openSubTasks: dat.openSubTasks,
    totalCompletedCount: dat.totalCompletedCount,
    focusCount: dat.focusCount,
    backlogCount: dat.backlogCount,
    recentCompletedCount: dat.recentCompletedCount,
    focusPoints: dat.focusPoints,
    backlogPoints: dat.backlogPoints,
  };
  return field;
};

export const parseUsers = (data: ResponseUserType[]) => {
  const newData: UserFieldType[] = [];
  isArray(data) &&
    data.forEach((dat) => {
      const field = parseUser(dat);
      newData.push(field);
    });

  return newData;
};

export const parseUser = (dat: ResponseUserType): UserFieldType => {
  let name = `${dat.firstName ? dat.firstName : ''} ${dat.lastName ? dat.lastName : ''}`;
  name = name.trim();

  if (name === '') {
    const str = dat.email ? dat.email : '';
    const nameParts = str.split('@');
    name = nameParts.length === 2 ? nameParts[0] : 'No Name';
  }

  const field: UserFieldType = {
    id: dat.userId ? dat.userId : dat.id,
    display: name,
    name: name,
    email: dat.email,
    profileImage:
      dat.profileImage && dat.profileImage !== '' ? dat.profileImage : createUserInitials(dat),
    active: dat.active,
    activeTheme: dat.activeTheme ? dat.activeTheme : undefined,
    activeThemeSlug: dat.activeThemeSlug ? dat.activeThemeSlug : undefined,
    competing: dat.competing ? dat.competing : undefined,
    workspace: dat.workspace ? dat.workspace : undefined,
    workspaceId: dat.workspaceId ? dat.workspaceId : undefined,
    workspaceIds: dat.workspaceIds ? dat.workspaceIds : undefined,
    workspaceSequences: dat.workspaceSequences ? dat.workspaceSequences : undefined,
    workspaces: dat.workspaces ? dat.workspaces : undefined,
    joiningDate: dat.joiningDate ? dat.joiningDate : undefined,
    firstLogInDate: dat.firstLogInDate ? dat.firstLogInDate : undefined,
    lastLogInDate: dat.lastLogInDate ? dat.lastLogInDate : undefined,
    role: dat.role ? dat.role : undefined,
    totalCompletedPoints: dat.totalCompletedPoints,
    totalCompletedTasks: dat.totalCompletedTasks,
    totalCreatedTasks: dat.totalCreatedTasks,
    totalLoggedIn: dat.totalLoggedIn,
    totalProjectsCreated: dat.totalProjectsCreated,
    tutorialCounts: dat.tutorialCounts ? dat.tutorialCounts : undefined,
    abortController: new AbortController(),
    invitationStatus: dat.invitationStatus ? dat.invitationStatus : undefined,
    invitedOn: dat.invitedOn ? dat.invitedOn : undefined,
  };
  return field;
};

export const parseTags = (data: TagType[] | TagObjectType[]): TagType[] | TagObjectType[] => {
  let tagList: TagObjectType[] = [],
    list1: TagType[] = [],
    list2: TagObjectType[] = [],
    item: TagType | TagObjectType | null = null;
  isArray(data) &&
    data.forEach((dat) => {
      if (dat) {
        tagList = [];
        if (dat.projectId === null || isArray(dat.tags) || (dat.tags && dat.tags.length === 0)) {
          dat.tags &&
            dat.tags.forEach((tag) => {
              if (tag && tag.id && tag.name) {
                tagList.push(parseTag(tag));
              }
            });
          item = {
            projectId: dat.projectId,
            tags: sortStringData(tagList, true),
          };
          list1 = [...list1, item];
        } else {
          item = { id: dat.id, name: dat.name };
          list2 = [...list2, item];
        }
      }
    });

  if (list1.length > 0) {
    return list1;
  }
  return [...sortStringData(list2, true)];
};

export const parseTag = (tag: TagObjectType) => {
  return { id: tag.id + '', name: tag.name };
};

export const parseData = (data: ResponseTaskInterface[], stateType?: StateType | null) => {
  const newData: TaskObjectType[] = [];
  data.forEach((dat) => {
    if (dat && dat.id) newData.push(parseTask(dat, stateType));
  });
  return newData;
};

export const createNewTaskComment = (
  value: string,
  tempId: string,
  taskType: TaskType,
  projectId: string | undefined,
  assignee: UserFieldType | undefined,
  taggedUsers: CommentTaggedUsersType[] | undefined,
  formData?: FormData | null,
): CommentType => {
  const task = {
    id: tempId,
    tempId: tempId,
    isTemp: true,
    name: value,
    tempName: value,
    projectId: projectId ? projectId : null,
    createdBy: assignee,
    modified: new Date().getTime(),
    created: new Date(),
    taskType: taskType,
    type: null,
    url: formData ? 'loading' : null,
    taggedUsers: taggedUsers ? taggedUsers : [],
  };

  return { ...task };
};

const parseTaskComments = (
  data: CommentType[],
  project: ResponseProjectType | undefined,
  taskType?: TaskType,
) => {
  const newData: CommentType[] = [];
  if (data && data.length > 0 && data[0] !== null) {
    data.forEach((dat) => {
      newData.push(parseTaskComment(dat, project, taskType));
    });
    return sortData(newData, 'created');
  }
  return [];
};

export const parseTaskComment = (
  comment: ResponseCommentType,
  project: ResponseProjectType | undefined,
  taskType?: TaskType,
) => {
  const data = {
    id: comment.id,
    tempId: randomNumber() + '',
    name: comment.name,
    tempName: comment.name,
    isTemp: false,
    modified: comment.modified ? parseToDateObjectFromTimestamp(comment.modified) : null,
    createdBy: comment.createdBy ? parseUser(comment.createdBy) : null,
    created: comment.createdString
      ? parseToDateObjectFromString(comment.createdString, 'YYYY-MM-DDTHH:mm:ssZ')
      : null,
    projectId: project ? project.id : null,
    taskType: taskType,
    type: comment.type,
    url: comment.url ? comment.url : null,
    thumbnail: comment.thumbnail,
    taggedUsers: comment.taggedUsers ? comment.taggedUsers : [],
  };

  return data as CommentType;
};

export const parseFolders = (data: ResponseFolderListType[]) => {
  const newData: FolderType[] = [];
  let unLinkedProjects: ProjectObjectType[] = [];
  let folderProjects: ProjectObjectType[] = [];
  data &&
    data.forEach((item) => {
      // /* If the Folder is not null then create a list of it
      if (item.folder && item.folder.id) {
        const field: FolderType = {
          id: item.folder.id,
          name: item.folder.name,
          isFolder: true,
          isOpen: item.folder.open ? item.folder.open : false,
          archived: false,
          createdBy: item.folder.createdBy,
          modifiedby: item.folder.modifiedby,
          created: item.folder.created ? parseToDateObjectFromTimestamp(item.folder.created) : null,
          modified: item.folder.modified
            ? parseToDateObjectFromTimestamp(item.folder.modified)
            : null,
          workspace: item.folder.workspace ? item.folder.workspace : null,
          sequence: item.folder.sequence ? item.folder.sequence : 0,
          projects: parseProjectsForMentions(item.projects),
        };
        newData.push(field);
        const temp = parseProjectsForMentions(item.projects, item.folder.id, item.folder.name);
        folderProjects = [...folderProjects, ...temp];
      } else {
        unLinkedProjects = parseProjectsForMentions(item.projects);
      }
    });

  return {
    folders: sortData([...newData, ...unLinkedProjects], 'sequence', true) as FolderType[],
    projects: sortData(
      [...folderProjects, ...unLinkedProjects],
      'sequence',
      true,
    ) as ProjectObjectType[],
  };
};

export const convertArrayToObject = <T>(array: Array<T>, key: string) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const parseFolder = (folder: FolderType) => {
  let field = {};
  if (folder && folder.id) {
    field = {
      id: folder.id,
      name: folder.name,
      isFolder: true,
      isOpen: false,
      archived: false,
      createdBy: folder.createdBy,
      modifiedby: folder.modifiedby,
      created: folder.created ? parseToDateObjectFromTimestamp(folder.created) : null,
      modified: folder.modified ? parseToDateObjectFromTimestamp(folder.modified) : null,
      workspace: folder.workspace ? folder.workspace : null,
      projects: parseProjectsForMentions([]),
    };
  }

  return field;
};

export const getActiveProjects = (projects: ProjectObjectType[]) => {
  if (!projects) return [];

  return projects.filter(function (el) {
    return el.archived !== true;
  });
};

export const parseTutorials = (data: ResponseTutorialDBType[]) => {
  const newData: TutorialDBType[] = [];

  isArray(data) &&
    data.forEach((dat) => {
      if (dat && dat.id) {
        newData.push(parseTutorial(dat));
      }
    });

  return newData;
};

export const parseTutorial = (data: ResponseTutorialDBType) => {
  return {
    id: data.id,
    currentStep: data.activeStep ? data.activeStep : 0,
    name: data.tutorial && data.tutorial.name ? data.tutorial.name : 'INTRODUCTION',
    isActive: data.active ? data.active : false,
    isCompleted: data.completed ? data.completed : false,
    totalSteps: data.tutorial && data.tutorial.totalSteps ? data.tutorial.totalSteps : 0,
  };
};

export const parseTriggers = (data: TriggerObjectType[]) => {
  const newData: TriggerObjectType[] = [];

  isArray(data) &&
    data.forEach((dat) => {
      if (dat && dat.id) {
        newData.push(parseTrigger(dat));
      }
    });

  return newData;
};

export const parseTrigger = (data: TriggerObjectType) => {
  return {
    id: data.id,
    name: data.name,
    type: data.type,
    description: data.description,
    key: data.key,
  };
};

export const parseThemes = (
  data: ResponseThemeObjectInterface[] | ThemeInstanceInterface[],
  isPurchased: boolean,
) => {
  if (isPurchased) {
    const newData: ThemeInstanceInterface[] = [];
    isArray(data) &&
      data.forEach((dat) => {
        if (dat && dat.id) {
          newData.push(parsePurchasedTheme(dat));
        }
      });
    return newData;
  }

  const newData: ThemeObjectInterface[] = [];

  isArray(data) &&
    data.forEach((dat) => {
      if (dat && dat.id) {
        newData.push(parseTheme(dat));
      }
    });

  return newData;
};

export const parseTheme = (data: ResponseThemeObjectInterface): ThemeObjectInterface => {
  return {
    id: data.id,
    name: data.name,
    price: data.price,
    totalQuantity: data.totalQuantity,
    remainingQuantity: data.remainingQuantity,
    rarity: data.rarity,
    difficulty: data.difficulty,
    pointLevels: data.pointLevels,
    active: data.active,
    releaseDate: data.timeOfLaunch ? formatDateForView(data.timeOfLaunch) : null,
    creationDate: data.created ? formatDateForView(data.created) : null,
    previewWebUrl: data.previewWebUrl,
    previewMobileUrl: data.previewMobileUrl,
    uploadedBy: data.uploadedBy,
    pointLevelsWithInactiveTriggers: data.pointLevelsWithInactiveTriggers,
  };
};

export const parsePurchasedTheme = (data: ResponseThemeInstanceInterface) => {
  return {
    id: data.id,
    themeId: data.themeId,
    purchasedFrom: data.purchaseFromUserId,
    purchasedOn: data.purchasedOn ? formatDateForView(data.purchasedOn) : null,
    purchaseValue: data.purchaseValue,
    points: data.points,
    mintNumber: data.mintNumber,
    numberOfOwners: data.numberOfOwners,
    firstEquipped:
      data.firstEquipped && data.purchasedOn ? formatDateForView(data.purchasedOn) : null,
    level: data.level,
    active: data.active,
    equipped: data.equipped,
    progress: data.progress,
    theme: data.theme ? parseTheme(data.theme) : null,
  } as ThemeInstanceInterface;
};

export const parseWorkspace = (dat: ResponseWorkspaceObjectType) => {
  const newData = {
    workspace: dat.workspace,
    id: dat.workspaceId,
    totalUserCount: dat.totalUserCount,
    activeUserCount: dat.activeUserCount,
    inActiveUsers: dat.inActiveUserCount,
    joiningDate: dat.joiningDate ? parseToDateObjectFromTimestamp(dat.joiningDate) : null,
    lastLogin: dat.lastLogin ? parseToDateObjectFromTimestamp(dat.lastLogin) : null,
    totalTasksInWorkspace: dat.totalTasksInWorkspace ?? null,
    totalProjectsCreated: dat.totalProjectsCreated ?? null,
    workspaceOwner: dat.workspaceOwner ?? null,
    lastLoggedInEmail: dat.lastLoggedInEmail ?? null,
    active: dat.active ?? false,
    tutorialSteps: dat.tutorialSteps ?? null,
  };
  return newData;
};

export const parseRecurringTaskModel = (task: ResponseRecurringTaskInterface): TaskObjectType => {
  return {
    id: task.id ? task.id : null,
    comments: [],
    sequence: 0,
    tempId: randomNumber() + '',
    name: task.name,
    tempName: task.name,
    taskNumber: 0,
    createdUser: '',
    createdDate: null,
    modifiedUser: '',
    modifiedDate: null,
    points: task.points,
    size: task.size ? task.size : null,
    project: task.project,
    projectId: task.projectId, // for templates this is used as tag is null but this param contains templateId
    tags: task.tags ? parseTags(task.tags) : [],
    tagInfo: task.tagInfo,
    assignee: task.recurringTaskAssignee
      ? {
          id: task.recurringTaskAssignee.id,
          name: getName(task.recurringTaskAssignee),
          profileImage: task.recurringTaskAssignee.profileImage,
        }
      : task.assignee
        ? {
            id: task.assignee.id,
            name: getName(task.assignee),
            profileImage: task.assignee.profileImage,
          }
        : null,
    checked: false,
    taskType: task.taskType ? task.taskType.toUpperCase() : 'FOCUS',
    startDate: task.startDate && task.endDate ? parseToDateObjectFromString(task.startDate) : null,
    endDate: task.startDate && task.endDate ? parseToDateObjectFromString(task.endDate) : null,
    dueDate: task.dueDate ? parseToDateObjectFromString(task.dueDate) : null,
    meeting: null,
    animation: 'ADD_TASK',
    subTasks: [],
    collapsedSubTasks: task.collapsedSubTasks ? task.collapsedSubTasks : false,
  } as TaskObjectType;
};

const parseRecurringTaskMeta = (
  recurringTask: RecurringTaskMetaType | ResponseRecurringTaskInterface,
  created: string | undefined,
  modified: string | undefined,
  createdBy: ResponseUserType,
) => {
  return {
    id: recurringTask.id,
    created: created ? parseToDateObjectFromTimestamp(created) : null,
    modified: modified ? parseToDateObjectFromTimestamp(modified) : null,
    nextRecurrence: recurringTask.nextRecurrence
      ? parseToDateObjectFromTimestamp(recurringTask.nextRecurrence)
      : null,
    repeatType: recurringTask.repeatType,
    repeatFrequency: recurringTask.repeatFrequency,
    repeatDays: recurringTask.repeatDays ? recurringTask.repeatDays : [],
    executionTime: recurringTask.executionTime
      ? parseToDateObjectFromStringAsUTC(recurringTask.executionTime, 'hh:mm a')
      : null,
    createdBy: createdBy,
  };
};

export const parseRecurringTask = (recurringTask: ResponseTaskInterface) => {
  log('parseRecurringTask', recurringTask);

  let newRecurringTask: TaskObjectType = parseRecurringTaskModel(recurringTask);

  newRecurringTask.recurringTaskMeta = parseRecurringTaskMeta(
    recurringTask,
    recurringTask.created,
    recurringTask.modified,
    recurringTask.createdBy,
  );

  newRecurringTask = {
    ...newRecurringTask,
    createdUser: newRecurringTask.recurringTaskMeta.createdBy
      ? getName(newRecurringTask.recurringTaskMeta.createdBy)
      : '',
    createdDate: newRecurringTask.recurringTaskMeta.created,
    modifiedDate: newRecurringTask.recurringTaskMeta.modified,
    id: newRecurringTask.recurringTaskMeta.id,
    subTasks: recurringTask.subTasks ? parseRecurringSubTasks(recurringTask.subTasks) : [],
  };

  return newRecurringTask;
};

const parseRecurringSubTasks = (data: ResponseTaskInterface[]) => {
  const newData: TaskObjectType[] = [];
  data &&
    data.forEach((dat) => {
      if (dat) newData.push(parseRecurringTaskModel(dat));
    });

  return newData;
};

export const parseRecurringTasks = (data: ResponseTaskInterface[]) => {
  const newData: TaskObjectType[] = [];
  data &&
    data.forEach((dat) => {
      if (dat && dat.id) newData.push(parseRecurringTask(dat));
    });

  log('parseRecurringTasks', data);
  return newData;
};

export const parseProjectTemplate = (projectTemplate: ResponseTemplateType) => {
  log('parseProjectTemplate', projectTemplate);

  const newProjectTemplate = {
    id: projectTemplate.id,
    name: projectTemplate.name,
    projectId: projectTemplate.projectId,
    createdBy: projectTemplate.createdBy ? getName(projectTemplate.createdBy) : null,
    created: projectTemplate.created ? formatDateForView(projectTemplate.created) : null,
    createdByObj: projectTemplate.createdBy ? projectTemplate.createdBy : null,
    createdObj: projectTemplate.created ? projectTemplate.created : null,
    tasksCount: projectTemplate.numberOfTasks ? projectTemplate.numberOfTasks : 0,
    points: projectTemplate.points ? projectTemplate.points : 0,
    membersCount: projectTemplate.users ? projectTemplate.users.length : 0,
    projectsCount: projectTemplate.numberOfProjectsCreated
      ? projectTemplate.numberOfProjectsCreated
      : 0,
    users: parseUsers(projectTemplate.users),
  };

  return newProjectTemplate;
};

export const parseProjectTemplates = (data: ResponseTemplateType[]) => {
  const newData: TemplateType[] = [];
  data &&
    data.forEach((dat) => {
      if (dat && dat.id) newData.push(parseProjectTemplate(dat));
    });
  return newData;
};

export function getFilterStorageName(
  name: string,
  stateType?: StateType | null,
  id?: string | null,
) {
  return `${filterPrefix}_${name}${stateType ? stateType : ''}${id ? id : ''}`;
}
export function setFilterInLocalStorage(
  name: TaskFiltersType,
  value: string,
  stateType?: StateType | null,
  id?: string | null,
) {
  const storageName = getFilterStorageName(name, stateType, id);
  setItem(storageName, value);
}

export function removeFilterFromLocalStorage(
  name: string,
  stateType: StateType,
  id?: string | null,
) {
  const storageName = getFilterStorageName(name, stateType, id);
  removeItem(storageName);
}

export function setTagFilterListInLocalStorage(stateType: StateType, tagList: string) {
  const tagFilterStorage = getFilterStorageName('tagFilterStorage', stateType);
  setItem(tagFilterStorage, tagList);
}

export function removeTagFilterListFromLocalStorage(stateType: StateType) {
  const storageName = getFilterStorageName('tagFilterStorage', stateType);
  removeItem(storageName);
}

export function getCountOfTasks(tasks: TaskObjectType[]): number {
  let count = 0;
  tasks.forEach((item) => {
    count = count + 1;
    if (item.subTasks && item.subTasks.length > 0) {
      const openSubTasks = item.subTasks.filter((sub) => sub.checked === false);
      count = count + openSubTasks.length;
    }
  });
  return count;
}

export const getStateType = (
  state: StateDataObjectType,
  dataType: TaskType,
  stateType: StateType,
): TaskListType => {
  let stateTypeField = 'taskData';

  if (stateType === 'PROJECT') stateTypeField = 'projectData';
  else if (stateType === 'INDIVIDUAL') stateTypeField = 'userData';
  else if (stateType === 'TUTORIAL') stateTypeField = 'tutorialData';
  else if (stateType === 'TEMPLATE') stateTypeField = 'templateData';

  return state[stateTypeField][dataType];
};

export const getReducerTypeMain = (state: StateDataObjectType, stateType: StateType) => {
  let stateTypeField = 'taskData';

  if (stateType === 'PROJECT') stateTypeField = 'projectData';
  else if (stateType === 'INDIVIDUAL') stateTypeField = 'userData';
  else if (stateType === 'TUTORIAL') stateTypeField = 'tutorialData';
  else if (stateType === 'TEMPLATE') stateTypeField = 'templateData';
  return state[stateTypeField];
};

export const parseUserProjects = (data: ResponseProjectType[]) => {
  let projects: ProjectObjectType[] = [];
  data &&
    data.forEach((item) => {
      if (item.id) {
        const project: ProjectObjectType = {
          id: item.id,
          name: item.name,
          display: item.name,
        };
        projects = [...projects, project];
      }
    });
  return projects;
};
