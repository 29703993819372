import { useState, useMemo, useEffect, useRef, useCallback } from 'preact/hooks';
import { memo } from 'preact/compat';
import { log } from 'utils';
import InputBox from 'components/TaskItems/IndividualItem/InputBox';
import ProjectSelection from 'components/TaskItems/IndividualItem/ProjectSelection';
import AssigneeSelection from 'components/TaskItems/IndividualItem/AssigneeSelection';
import SizeSelection from 'components/TaskItems/IndividualItem/SizeSelection';
import { getUserId, getUserName } from 'services/AuthService';
import {
  isUserScreen,
  isProjectTaskScreen,
  isRecurringTasksScreen,
  isHomeScreen,
  isProjectTemplateScreen,
} from 'utils/task';
import { Strings } from 'resources';
import RecurringIcon from '../TaskItems/IndividualItem/RecurringIcon';
import { useRecoilValue } from 'recoil';
import { allSizes } from 'utils/size';
import { accountDataSelector, allTagsState, allUsersState } from 'recoil/AccountState';
import { allProjectsState, currentProjectIdState } from 'recoil/ProjectState';
import {
  allTemplateTagsState,
  allTemplatesState,
  currentTemplateIdState,
} from 'recoil/TemplateState';
import { allUserTagsState, currentUserIdState } from 'recoil/UserState';
import AvatarIcon from 'assets/images/Avatar.png';
import { addTopbarTask } from 'recoil/TaskState/update';

type TopBarInputPropsType = {
  currentRoute: string;
};
function TopBarInput(props: TopBarInputPropsType) {
  const { currentRoute } = props;

  // log('render components/TopBarInput', props);

  const userId = getUserId();
  const userName = getUserName();
  const [itemProject, setItemProject] = useState<string | null>(null);
  const [itemAssignee, setItemAssignee] = useState<string | null>(null);
  const [itemSize, setItemSize] = useState<SizeOptionsType | undefined>(undefined);
  const [itemTags, setItemTags] = useState<TagObjectType[]>([]);
  const [itemValue, setItemValue] = useState('');
  const [updateValue, setUpdateValue] = useState(0);
  const [itemTemplate, setItemTemplate] = useState<string | null>(null);

  // Recurring task
  const [showRecurringTaskModal, setShowRecurringTaskModal] = useState(false);

  const accountData = useRecoilValue(accountDataSelector);
  // Values from redux
  const allProjects = useRecoilValue(allProjectsState);
  const allTemplates = useRecoilValue(allTemplatesState);
  const allUsers: UserFieldType[] | null = useRecoilValue(allUsersState);

  const allAccountUserTags = useRecoilValue(allTagsState);
  const allUserTags = useRecoilValue(allUserTagsState);
  const allTemplateTags = useRecoilValue(allTemplateTagsState);
  const spellChecker = accountData.accountSettings.spellChecker;
  const assignDefaultToSelf = accountData.accountSettings.assignDefaultToSelf;

  const [projectFilterValue, setProjectFilterValue] = useState('');

  const updateProjectFilterValue = (value) => setProjectFilterValue(value);
  const accountProfileImage = accountData.account?.profileImage;

  const stateType = useMemo<StateType | null>(() => {
    if (isUserScreen(currentRoute)) return 'INDIVIDUAL';
    else if (isProjectTaskScreen(currentRoute)) return 'PROJECT';
    else if (isHomeScreen(currentRoute)) return 'USER';
    else if (isProjectTemplateScreen(currentRoute)) return 'TEMPLATE';
    else if (isRecurringTasksScreen(currentRoute)) return 'RECURRING';

    return null;
  }, [currentRoute]);

  useEffect(() => {
    resetState();
  }, [accountData.account?.workspace]);

  const getAllUsers = useCallback(() => {
    return itemTemplate
      ? getSelectedTemplateUsers(itemTemplate)
      : itemProject
        ? getSelectedProjectUsers(itemProject)
        : allUsers
          ? ([{ id: userId, name: userName }, ...allUsers] as UserFieldType[])
          : ([{ id: userId, name: userName }] as UserFieldType[]);
  }, [itemProject, allUsers, allProjects, itemTemplate, allTemplates]);

  const currentProjectId = useRecoilValue(currentProjectIdState);

  const currentUserId = useRecoilValue(currentUserIdState);

  const currentTemplateId = useRecoilValue(currentTemplateIdState);

  const getTagList = (tagList: TagType[]): TagObjectType[] => {
    let index = -1;
    if (itemProject) {
      index = tagList.findIndex((data) => data.projectId === itemProject);
      return index > -1 && tagList[index].tags ? tagList[index].tags! : [];
    } else {
      return tagList.length > 0 && tagList[tagList.length - 1].tags
        ? tagList[tagList.length - 1].tags!
        : [];
    }
  };

  /*
   * Get all tags
   *
   * Based upon stateType and if item project is set or not
   *
   * returns Array
   */
  const allTags = useMemo(() => {
    let data: TagObjectType[] = [];

    if (stateType === 'INDIVIDUAL') {
      data = getTagList(allUserTags);
    } else if (stateType === 'TEMPLATE') {
      data = allTemplateTags;
    } else {
      data = getTagList(allAccountUserTags);
    }
    return data;
  }, [itemProject, allUserTags, allAccountUserTags, allTemplateTags, stateType]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      log('currentRoute updated', currentRoute, currentProjectId, currentUserId, currentTemplateId);
      updateState();
    }
  }, [currentRoute, currentProjectId, currentUserId, currentTemplateId]);

  const updateState = () => {
    if (stateType === 'PROJECT') {
      if (currentProjectId) updateProject(currentProjectId);
      setItemTemplate(null);
      setItemAssignee(null);
      setItemTags([]);
    } else if (stateType === 'INDIVIDUAL') {
      if (currentUserId) updateAssignee(currentUserId);
      setItemProject(null);
      setItemTemplate(null);
      setItemTags([]);
    } else if (stateType === 'USER') {
      setItemAssignee(null);
      setItemProject(null);
      setItemTemplate(null);
      setItemTags([]);
    } else if (stateType === 'TEMPLATE') {
      setItemAssignee(null);
      setItemProject(null);
      setItemTemplate(currentTemplateId);
      setItemTags([]);
    }
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const getSelectedProjectUsers = (project: string | null) => {
    if (!project) return [];
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === project) : -1;

      if (index === -1) return [];
      else return allProjects ? allProjects[index].users : [];
    }
  };

  /*
   * Get selected template users
   *
   * returns List of users
   */
  const getSelectedTemplateUsers = (template: string | null) => {
    if (!template) return [];
    else {
      if (allTemplates === null) return [];
      const index = allTemplates.findIndex((item) => item.id === template);

      if (index === -1) return [];
      else return allTemplates[index].users;
    }
  };

  /*
   * Remove tag from task
   *
   * tagId String
   * tagName String
   *
   * returns String
   */
  const removeTagFromTask = (tagId: string | null, tagName: string) => {
    if (!tagId) tagId = getTagIdForName(tagName);

    if (tagId) {
      removeTagFromTaskTags(tagId);
    }
  };

  const removeTagFromTaskTags = (id: string) => {
    let tags = itemTags;

    tags = tags.filter((tag) => tag.id !== id);

    setItemTags(tags);
  };

  const getTagIdForName = (tagName: string) => {
    const index =
      allTags && allTags.findIndex((tag) => tag.name.toUpperCase() === tagName.toUpperCase());

    if (index !== -1) return allTags[index].id;

    return null;
  };

  const addNewTask = (
    value: string,
    assignSelf?: boolean,
    tags?: TagObjectType[],
    sizeId?: SizeOptionsType,
    defaultSize?: SizeOptionsType,
  ) => {
    if (value.trim() === '') return;
    addTopbarTask(
      value,
      'BACKLOG',
      stateType === 'TEMPLATE' ? itemTemplate : itemProject ? itemProject : null,
      sizeId
        ? sizeId
        : itemSize
          ? itemSize
          : defaultSize
            ? defaultSize
            : accountData.accountSettings.defaultPreferredSize,
      stateType === 'TEMPLATE'
        ? itemAssignee
          ? itemAssignee
          : null
        : itemAssignee
          ? itemAssignee
          : itemProject
            ? assignSelf
              ? userId
              : null
            : userId,
      tags ? getTagIds(tags) : getItemTags(),
      stateType
        ? stateType === 'PROJECT' && itemProject === null
          ? undefined
          : stateType
        : undefined,
    );

    resetState();
  };

  const resetState = () => {
    setItemTags([]);
    setItemValue('');
    setUpdateValue(+new Date());
    setItemSize(undefined);
    // Recurring task
    setShowRecurringTaskModal(false);
  };

  const getItemTags = () => {
    const itemTagIds: string[] = [];

    itemTags.forEach((itemTag) => {
      if (itemTag?.id) itemTagIds.push(itemTag.id);
    });

    return itemTagIds;
  };

  const getTagIds = (tags: TagObjectType[]) => {
    const itemTagIds: string[] = [];

    tags.forEach((itemTag) => {
      if (itemTag?.id) itemTagIds.push(itemTag.id);
    });

    return itemTagIds;
  };

  const renderTaskTags = () => {
    return (
      <div className='item-tags-container'>
        {itemTags.map((tag, index) => (
          <span className='tag-entry' key={tag.id + index}>
            <span className='tag-text'>{tag.name}</span>{' '}
            <span className='close-icon' onClick={() => removeTagFromTask(tag.id, tag.name)}>
              x
            </span>
          </span>
        ))}
      </div>
    );
  };

  /*
   * Render size Selection
   *
   * returns React.DOM
   */
  const renderSizeSelection = () => {
    if (!itemSize) return null;

    return (
      <SizeSelection
        tempId={'Create-task-size'}
        itemSize={itemSize}
        showSize={accountData.accountSettings.size}
        isDisabled={false}
        updateSize={(size) => setItemSize(size)}
        allSizes={allSizes}
      />
    );
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectName = useMemo(() => {
    if (!itemProject) return '';
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === itemProject) : -1;

      if (allProjects && index === -1 && stateType !== 'PROJECT') {
        setItemProject(null);
        return '';
      } else if (allProjects && index !== -1) return allProjects ? allProjects[index].name : '';
    }
  }, [itemProject, allProjects]);

  /*
   * Get Project name
   *
   * returns String
   */
  const renderProjectName = useMemo(() => {
    const itemProj = itemProject ? selectedProjectName : 'No Project';

    return <span className='project-name'>{itemProj}</span>;
  }, [selectedProjectName]);

  const filteredProjects = useMemo(() => {
    const projects = allProjects ? allProjects.filter((proj) => proj.archived !== true) : [];
    const filteredProjectsList: ProjectObjectType[] = [];
    const filter = projectFilterValue.trim().toUpperCase();
    projects.forEach((proj) => {
      const name = proj.name?.toUpperCase();
      if (itemAssignee) {
        const ids = proj.users?.map((o) => o.id);
        const filteredUsers = proj.users?.filter(({ id }, index) => !ids?.includes(id, index + 1));
        filteredUsers?.forEach((user) => {
          if (filter) {
            if (itemAssignee === user.id && name?.includes(filter)) {
              filteredProjectsList.push(proj);
            }
          } else if (itemAssignee === user.id) {
            filteredProjectsList.push(proj);
          }
        });
      } else {
        if (name?.includes(filter)) filteredProjectsList.push(proj);
      }
    });
    return filteredProjectsList;
  }, [projectFilterValue, allProjects, itemAssignee]);
  /*
   * Render project Selection
   *
   * returns React.DOM
   */
  const renderProjectSelection = () => {
    if (!itemProject) return null;

    return (
      <ProjectSelection
        allProjects={filteredProjects}
        updateProjectFilterValue={updateProjectFilterValue}
        showProjectId={accountData.accountSettings.projectId}
        isSubtask={false}
        itemProject={itemProject ? { id: itemProject } : null}
        hasAssignee={true}
        projectNameWithTaskNumber={selectedProjectName}
        renderProjectNameWithTaskNumber={renderProjectName}
        isDisabled={false}
        updateProject={(project) => setItemProject(project)}
        stateType={stateType}
        overlayClass={''}
      />
    );
  };

  /*
   * Get selected assignee name
   *
   * returns String
   */
  const selectedAssigneeName = useMemo(() => {
    if (!itemAssignee) return '';
    else {
      if (itemAssignee === getUserId()) return userName;
      if (itemProject && allProjects) {
        const projects = allProjects.filter((proj) => proj.id === itemProject);
        if (projects.length > 0 && projects[0].users) {
          const index = projects[0].users.findIndex((item) => item.id === itemAssignee);
          if (index === -1) {
            setItemAssignee(null);
            return '';
          }
        }
      }
      if (allUsers === null) return '';
      const index = allUsers.findIndex((item) => item.id === itemAssignee);

      if (index === -1) return '';
      else return allUsers[index].name;
    }
  }, [itemAssignee, allUsers, allProjects]);

  /*
   * Get selected assignee profile image
   *
   * returns String
   */
  const selectedAssigneeProfileImage = useMemo(() => {
    if (!itemAssignee) return AvatarIcon;
    else {
      if (itemAssignee === getUserId()) {
        if (accountProfileImage) return accountProfileImage;
        else return AvatarIcon;
      }

      if (allUsers === null) return AvatarIcon;
      const index = allUsers.findIndex((item) => item.id === itemAssignee);

      if (index !== -1 && allUsers[index].profileImage) return allUsers[index].profileImage;

      return AvatarIcon;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Render assignee Selection
   *
   * returns React.DOM
   */
  const renderAssigneeSelection = () => {
    if (!itemAssignee) return null;

    return (
      <AssigneeSelection
        tempId='create-task-assignee'
        allUsers={getAllUsers()}
        selectedProjectUsers={getSelectedProjectUsers(itemProject)}
        selectedAssigneeName={selectedAssigneeName}
        selectedAssigneeProfileImage={selectedAssigneeProfileImage}
        stateType={stateType}
        itemAssignee={itemAssignee ? { id: itemAssignee } : null}
        hasProject={true}
        isDisabled={false}
        onBottom={true}
        updateAssignee={(assignee) => setItemAssignee(assignee)}
        userInWorkspace={true}
        forTopBar={true}
        selectedTemplateUsers={getSelectedTemplateUsers(itemTemplate)}
      />
    );
  };

  /*
   * Set task tags in backend
   *
   * tagsArray Array
   * add Boolean
   *
   * returns null
   */
  const setTaskTags = (id: string, name: string) => {
    log('setTaskTags', id, name);

    const index = itemTags && itemTags.findIndex((tag) => tag.name === name);

    if (index !== -1) return;

    setItemTags([...itemTags, { id, name }]);
  };

  const updateAssignee = (assigneeId: string | null) => {
    if (itemProject) {
      // see if project has this assignee

      const users = getSelectedProjectUsers(itemProject);
      const index = users?.findIndex((user) => user.id === assigneeId);

      if (index === -1) {
        setItemProject(null);
      }
    }

    setItemAssignee(assigneeId);
  };

  const updateProject = (projectId: string | null) => {
    setItemProject(projectId);

    if (projectId) itemProjectUpdated(projectId);
  };

  const itemProjectUpdated = (projectId: string) => {
    // check if item assignee is part of this project, if not remove assignee

    if (itemAssignee) {
      const users = getSelectedProjectUsers(projectId);

      const index = users?.findIndex((user) => user.id === itemAssignee);

      if (index === -1) setItemAssignee(null);
    }
  };

  /*
   * Render Input box
   *
   * returns React.DOM
   */

  const renderInputBox = () => {
    const inputProps = {
      dataType: 'BACKLOG' as TaskType,
      tempId: 'create-task-input',
      itemId: null,
      index: 0,
      parentId: null,
      parentIndex: 0,
      isDisabled: false,
      itemValue,
      updateValue,
      itemProject: { id: itemProject } as ProjectObjectType,
      itemAssignee: { id: itemAssignee } as UserFieldType,
      itemSize,
      itemTags,
      setTaskTags,
      valueUpdated: (value) => setItemValue(value),
      addNewTaskTopBar: (value, assign, tags, size, defaultSize) =>
        addNewTask(value, assign, tags, size, defaultSize),
      deleteTheTask: () => {},
      storeInputRefs: () => {},
    };
    return (
      <div className='input-box-in-con'>
        <span className='rotate-box'></span>
        <InputBox
          {...inputProps}
          key={currentRoute}
          usersData={getAllUsers()}
          sizesData={allSizes}
          projectsData={filteredProjects}
          tagsData={allTags}
          updateSize={(itemId) => setItemSize(itemId)}
          updateProject={(item) => item && updateProject(item === 'REMOVE' ? null : item)}
          updateAssignee={(item) => item && setItemAssignee(item === 'REMOVE' ? null : item)}
          placeholder={
            stateType === 'TEMPLATE'
              ? Strings.create_task_placeholder_for_template
              : Strings.create_task_placeholder
          }
          spellChecker={spellChecker}
          forTopBar={true}
          isDisabled={false}
          maxRows={2}
          showRecurringTaskModal={() => setShowRecurringTaskModal(true)}
          stateType={stateType}
          itemTemplate={itemTemplate}
          assignSelf={accountData.accountSettings.assignDefaultToSelf}
          defaultSize={accountData.accountSettings.defaultPreferredSize}
        />
      </div>
    );
  };
  const renderRecurringIcon = () => {
    const inputProps = {
      itemValue,
      itemAssignee,
      itemProject,
      itemSize,
      itemTags,
      assignDefaultToSelf,
      stateType,
      itemTemplate,
    };

    return showRecurringTaskModal ? (
      <RecurringIcon
        {...inputProps}
        userId={userId}
        toggleRecurringTaskModalDisplay={() => setShowRecurringTaskModal(!showRecurringTaskModal)}
        resetParentState={() => resetState()}
        showOnlyModal={showRecurringTaskModal}
        defaultSize={accountData.accountSettings.defaultPreferredSize}
      />
    ) : null;
  };

  return (
    <div className='topbar-input-box-con' key='new-task'>
      {renderAssigneeSelection()}
      {renderProjectSelection()}
      {renderInputBox()}
      {renderSizeSelection()}
      {renderTaskTags()}
      {renderRecurringIcon()}
    </div>
  );
}

export default memo(TopBarInput);
