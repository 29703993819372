import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/**
 * get all workspaces for super admin
 */
export async function getWorkspaces(payload: any, abortController: AbortController) {
  try {
    const response = await appClient.post(apiConfig.super_admin.get_workspaces, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get workspace user for super admin
 */
export async function getSuperAdminWorkspaceUsers(workspaceId: string, payload: any) {
  const url = `${apiConfig.super_admin.get_workspace_user}/${workspaceId}`;
  try {
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Activate deactivate workspace
 * workspace id String
 * active Boolean
 *
 * returns Object {data, error, status}
 */
export async function activateDeactivateWorkspace(
  workspaceId: string,
  active: boolean,
): Promise<AsyncResponseType> {
  const url = `${apiConfig.super_admin.activate_deactivate_workspace}/${workspaceId}/status/${active}`;

  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
