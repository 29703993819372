import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { TargetedEvent } from 'preact/compat';

type SmallButtonPropsType = {
  btnType?: string;
  clicked?: (e: TargetedEvent) => void;
  disabled?: boolean;
  loading?: boolean;
  children: preact.ComponentChildren;
};
const SmallButton = (props: SmallButtonPropsType) => {
  const { btnType, clicked, disabled, loading } = props;

  const renderLoadingIcon = () => {
    if (loading) {
      return (
        <span className='loading-con'>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      );
    }

    return null;
  };

  return (
    <button
      disabled={disabled || loading}
      className={['small-button', btnType].join(' ')}
      onClick={clicked}
    >
      {props.children} {renderLoadingIcon()}
    </button>
  );
};

export default SmallButton;
