import { useState, memo, useMemo } from 'preact/compat';
import _ from 'underscore';
import { highlightText, randomNumber } from 'utils/helpers';
import { Menu, MenuItem, MenuButton, FocusableItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { getUserId } from 'services/AuthService';

type AssigneeSelectionPropsType = {
  selectedAssigneeName: string;
  selectedAssigneeProfileImage: string;
  stateType: StateType;
  itemAssignee: UserFieldType;
  hasProject: boolean;
  isDisabled: boolean;
  selectedProjectUsers: UserFieldType[];
  allUsers: UserFieldType[];
  updateAssignee: (id: string | null) => void;
  userInWorkspace: boolean;
  onBottom: boolean;
  selectedTemplateUsers: UserFieldType[];
  overlayClass?: string;
};

function AssigneeSelection(props: AssigneeSelectionPropsType) {
  const {
    selectedAssigneeName,
    selectedAssigneeProfileImage,
    stateType,
    itemAssignee,
    hasProject,
    isDisabled,
    selectedProjectUsers,
    allUsers,
    updateAssignee,
    userInWorkspace,
    onBottom,
    selectedTemplateUsers,
    overlayClass,
  } = props;
  const userId = getUserId();
  const [assigneeFilterValue, setAssigneeFilterValue] = useState('');
  // const [toolTipStatus, setTooltipStatus] = useState(false);
  const filteredUsers = useMemo(() => {
    const users: UserFieldType[] =
      stateType === 'TEMPLATE'
        ? selectedTemplateUsers
        : stateType === 'PROJECT' || hasProject
          ? selectedProjectUsers
          : allUsers;
    const filterUsers: UserFieldType[] = [];
    const filter = assigneeFilterValue.trim().toUpperCase();

    users.forEach((user) => {
      const name = user.name?.toUpperCase();

      if (name?.includes(filter)) filterUsers.push(user);
    });

    return filterUsers;
  }, [assigneeFilterValue, selectedProjectUsers, allUsers, stateType, selectedTemplateUsers]);

  const renderRemoveItem = () => {
    if (!hasProject) return <MenuItem className='hidden'>hidden</MenuItem>;

    return (
      <MenuItem key='remove-item' onClick={() => updateAssignee(null)} className='remove-item'>
        Remove Assignee
      </MenuItem>
    );
  };

  // log('render components/TaskItems/IndividualItem/AssigneeSelection');

  const menuButton = () => {
    const tooltipId = randomNumber();
    return (
      <MenuButton
        disabled={isDisabled}
        className='item-btn assignee-btn'
        // onMouseEnter={() => setTooltipStatus(true)}
        // onMouseLeave={() => setTooltipStatus(false)}
      >
        <div
          className='item-selected'
          style={{ position: 'relative' }}
          data-tooltip-id={`tooltip-${tooltipId}`}
          data-class='assignee-tooltip'
          data-background-color='#332a7c'
          data-tip
        >
          <img
            className={`assignee-image ${
              userInWorkspace || (itemAssignee && itemAssignee.id === userId) ? '' : 'grayed-image'
            }`}
            src={
              userInWorkspace || (itemAssignee && itemAssignee.id === userId)
                ? selectedAssigneeProfileImage
                : itemAssignee && itemAssignee.profileImage
                  ? itemAssignee.profileImage
                  : ''
            }
            draggable={false}
            alt='user'
          />
          {/** @ts-ignore */}
          <ReactTooltip
            className='assignee-tooltip'
            id={`tooltip-${tooltipId}`}
            place={onBottom ? 'bottom' : 'top'}
          >
            {/** @ts-ignore */}
            <div className='tooltip-container'>
              {userInWorkspace || (itemAssignee && itemAssignee.id === userId)
                ? selectedAssigneeName
                  ? selectedAssigneeName
                  : 'Select a user'
                : `${itemAssignee ? itemAssignee.name : 'User'} - No longer exists in workspace`}
            </div>
          </ReactTooltip>
        </div>
      </MenuButton>
    );
  };

  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };

  return (
    <div className='item-container assignee-container filter-control' key='assignee-filter'>
      <Menu style={{ width: '50px' }} className='item-menu' menuButton={menuButton()}>
        <FocusableItem className='item-input'>
          {({ ref }) => (
            <input
              ref={ref}
              type='text'
              placeholder='Assignee'
              autoComplete='off'
              data-lpignore='true'
              data-form-type='other'
              value={assigneeFilterValue}
              onChange={(e) =>
                setAssigneeFilterValue(e.target ? (e.target as HTMLInputElement).value : '')
              }
              onKeyDown={highlightText}
            />
          )}
        </FocusableItem>
        {filteredUsers.map((userList, index) => (
          <MenuItem
            key={userList.id + index}
            onClick={() => updateAssignee(userList.id)}
            disabled={userList.id === itemAssignee?.id}
            value={userList.id}
          >
            {userList.name}
          </MenuItem>
        ))}
        {renderRemoveItem()}
      </Menu>
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps, nextProps) {
  if (prevProps.selectedAssigneeName !== nextProps.selectedAssigneeName) return false;

  if (prevProps.selectedAssigneeProfileImage !== nextProps.selectedAssigneeProfileImage)
    return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  if (prevProps.itemAssignee !== nextProps.itemAssignee) return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.hasProject !== nextProps.hasProject) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  if (!_.isEqual(prevProps.selectedProjectUsers, nextProps.selectedProjectUsers)) return false;

  if (!_.isEqual(prevProps.selectedTemplateUsers, nextProps.selectedTemplateUsers)) return false;

  return _.isEqual(prevProps.allUsers, nextProps.allUsers);
}

export default memo(AssigneeSelection, areEqual);
