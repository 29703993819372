import { useEffect } from 'preact/hooks';
import { Suspense } from 'preact/compat';
import { Fragment } from 'preact';
import { lazyWithRetry } from 'utils/helpers';
import { setPageTitle, log } from 'utils';
import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { open, close } from 'websocket/account';
import FullScreenLoader from 'components/UI/FullScreenLoader';
import LogoIcon from 'assets/images/Logo-Icon.png';
import { enableBrowserSound, getAllTriggers } from 'recoil/TriggerState/update';
import { initializeTaskFilters, updateTaskFilter } from 'recoil/TaskState/update';
import { getAllProjectTemplates } from 'recoil/TemplateState/update';
import TopBar from 'components/TopBar';
import LeftBar from 'components/LeftBar';

import { useWebNotification } from 'components/Notifications/useWebNotification';

import {
  isUserLoggedIn,
  getUserId,
  getUserWorkspaceId,
  reFetchUserSession,
  // getUserEmail,
  // getUserName,
} from 'services/AuthService';
import { getCurrentTheme, loadTheme, replaceTheme, getThemeSlug } from 'utils/theme';
// import rg4js from 'raygun4js';
import useWindowDimensions from 'utils/WindowDimensions';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  navigationDataSelector,
  showNotificationPermissionModalState,
} from 'recoil/NavigationState';
import {
  accountDataSelector,
  accountState,
  allTutorialsState,
  screenSaverState,
} from 'recoil/AccountState/index';
import {
  getAccountSettings,
  getAllTags,
  getAllThemes,
  getAllUsersWithCount,
  getInvitedMembers,
  getNewNotifications,
  getPurchasedThemes,
  getTutorialsData,
  getUserCounters,
  getWhatsNew,
} from 'recoil/AccountState/update';
import { getFoldersForUser } from 'recoil/ProjectState/update';
import { websocketStatusState } from 'recoil/TaskState';
import Notifications from 'components/Notifications';
import {
  userAnalyticsCompletedFilterSelector,
  userAnalyticsMonthlyCompletedFilterSelector,
} from 'recoil/UserAnalyticsState';
import moment from 'moment';
import { formatForAnalytics } from 'utils/task';
import { triggerDataSelector } from 'recoil/TriggerState';
import BgAnimation from 'animations/BgAnimation';
import { Strings } from 'resources';
import SmallButton from 'components/UI/SmallButton';

const NotificationPermission = lazyWithRetry(
  () => import('modals/NotificationPermission/NotificationPermission'),
);
const CreateNewProject = lazyWithRetry(() => import('modals/CreateNewProject'));
const CreateNewFolder = lazyWithRetry(() => import('modals/CreateNewFolder'));
const InviteMembers = lazyWithRetry(() => import('modals/InviteMembers'));
const UserProfile = lazyWithRetry(() => import('modals/UserProfile'));
const Search = lazyWithRetry(() => import('modals/Search'));
const WorkspaceSwitch = lazyWithRetry(() => import('components/WorkspaceSwitch'));
const TaskInfo = lazyWithRetry(() => import('modals/TaskInfo'));
const MultiMediaModal = lazyWithRetry(() => import('modals/MultiMediaModal'));
const EditProject = lazyWithRetry(() => import('modals/EditProject'));
const Feedback = lazyWithRetry(() => import('modals/Feedback'));
const WhatsNewModal = lazyWithRetry(() => import('modals/WhatsNewModal'));
const CreateWorkspace = lazyWithRetry(() => import('modals/CreateWorkspace'));
const CreateProjectTemplate = lazyWithRetry(() => import('modals/CreateProjectTemplate'));
const MessageModal = lazyWithRetry(() => import('modals/MessageModal'));
const PersonalInfoModal = lazyWithRetry(() => import('modals/PersonalInfoModal'));

type UserLayoutPropsType = {
  pageTitle?: string;
  stateType: StateType;
  path: string;
};
export default function UserLayout(props: UserLayoutPropsType) {
  const location = useLocation();
  const history = useNavigate();
  const { width } = useWindowDimensions();
  const accountData = useRecoilValue(accountDataSelector);
  const triggerData = useRecoilValue(triggerDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const [showNotificationPermission, setShowNotificationPermissionModalState] = useRecoilState(
    showNotificationPermissionModalState,
  );
  const { permission, showNotification } = useWebNotification();

  const { status } = isUserLoggedIn();

  const [screenSaver, setScreenSaverState] = useRecoilState(screenSaverState);

  useEffect(() => {
    if (!status) return;
    if (permission !== 'default') {
      setShowNotificationPermissionModalState(false);
      return;
    }
    setShowNotificationPermissionModalState(true);
  }, [permission]);

  useEffect(() => {
    if (!status) return;
    if (accountData.enableDesktopNotification && accountData.notificationPayloadItem)
      showNotification(
        accountData.notificationPayloadItem.userData + accountData.notificationPayloadItem.heading,
        {
          tag: accountData.notificationPayloadItem?.id,
          body: accountData.notificationPayloadItem?.body,
          lang: 'en',
          dir: 'ltr',
          data: accountData.notificationPayloadItem,
          icon: LogoIcon,
        },
      );
  }, [accountData.enableDesktopNotification, accountData.notificationPayloadItem]);

  const [completedFilter, setUserAnalyticsCompletedFilter] = useRecoilState(
    userAnalyticsCompletedFilterSelector,
  );
  const [monthlyCompletedFilter, setUserAnalyticsMonthlyCompletedFilter] = useRecoilState(
    userAnalyticsMonthlyCompletedFilterSelector,
  );
  useEffect(() => {
    if (!status) return;
    loadTheme(false);
  }, []);

  useEffect(() => {
    if (!status) return;
    if (accountData.allThemes.length > 0) {
      accountData.allThemes.forEach(async (element) => {
        try {
          await import(`../../assets/${getThemeSlug(element.name)}/styles/theme-card-front.css`);
        } catch {
          log('css file not found');
        }
        try {
          await import(`../../assets/${getThemeSlug(element.name)}/styles/theme-card-back.css`);
        } catch {
          log('css file not found');
        }
      });
    }
  }, [accountData.allThemes]);

  const websocketStatus = useRecoilValue(websocketStatusState);

  const userId = getUserId();
  const userWorkspaceId = getUserWorkspaceId();

  useEffect(() => {
    if (!status) return;
    if (!userId || !userWorkspaceId) {
      history('/');
      return;
    }
    // rg4js('setUser', {
    //   identifier: getUserId(),
    //   isAnonymous: false,
    //   email: getUserEmail(),
    //   fullName: getUserName(),
    // });

    // sending userid for google analytics
    if (document.location.origin === 'https://app.magictask.io') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: getUserId(),
      });
    }
    // Enabling browser sound on click
    document.addEventListener(
      'click',
      () => {
        enableBrowserSound();
      },
      { once: true },
    );
    if (!screenSaver) getInitialData();
    return () => {
      close();
    };
  }, [userWorkspaceId, userId, screenSaver]);

  const setInitialFilters = async () => {
    if (!completedFilter.assignee.includes(userId)) {
      const filter: UserAnalyticsCompletedFilterType = {
        ...completedFilter,
        assignee: [...completedFilter.assignee, userId],
        workspace: [userWorkspaceId],
      };
      setUserAnalyticsCompletedFilter(filter);
    }
    let filter: UserAnalyticsMonthlyCompletedFilterType = {
      ...monthlyCompletedFilter,
      workspace: [userWorkspaceId],
    };
    if (!monthlyCompletedFilter.assignee.includes(userId)) {
      filter = {
        ...monthlyCompletedFilter,
        assignee: [...completedFilter.assignee, userId],
      };
    }
    if (
      monthlyCompletedFilter.modified.value === 'last12Months' &&
      (monthlyCompletedFilter.modified.start === undefined ||
        monthlyCompletedFilter.modified.end === undefined)
    ) {
      const firstDay = moment().subtract(11, 'months').startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...filter,
        modified: {
          value: 'last12Months',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    }
    setUserAnalyticsMonthlyCompletedFilter(filter);
  };

  const getInitialData = async () => {
    reFetchUserSession();

    getWhatsNew();
    getUserCounters();

    getAllTags(userId);

    /* GET Personal or Work Folders */
    getFoldersForUser(userId);
    getAllUsersWithCount();
    getInvitedMembers();

    getNewNotifications('Un-read', 0);

    /* Get Account Settings Details */
    getAccountSettings(userId, true);

    // Initialize all filters from localStorage
    initializeTaskFilters();

    getTutorialsData();

    getAllThemes();

    getPurchasedThemes();

    getAllTriggers();

    getAllProjectTemplates();

    updateTaskFilter('createdByFilter', [userId], 'RECURRING', null);
    setInitialFilters();
    open();
  };

  const getWebSocketLoaderTitle = () => {
    if (navigationData.isWorkspaceSwitching) return 'Switching workspace, refreshing data...';
    if (websocketStatus === 'INIT') return 'Establishing connection, Please wait...';

    return 'Loading, Please wait...';
  };

  // component did mount
  useEffect(() => {
    if (props.pageTitle) setPageTitle(props.pageTitle);

    // rg4js('trackEvent', { type: 'pageView', path: '/' + window.location.pathname });
  }, [props.pageTitle]);

  const allTutorials = useRecoilValue(allTutorialsState);

  useEffect(() => {
    if (!status) return;
    if (
      allTutorials &&
      allTutorials.length > 0 &&
      !navigationData.showWhatsNewModal &&
      width > 800
    ) {
      const activeTutorialIndex = allTutorials.findIndex(
        (item) => item.isActive === true && item.isCompleted === false,
      );
      if (activeTutorialIndex !== -1) {
        history('/user-tutorial');
      }
    }
  }, [allTutorials]);

  const account = useRecoilValue(accountState);

  useEffect(() => {
    if (!status) return;
    if (account && account.activeThemeSlug) {
      const currentTheme = getCurrentTheme();

      const newTheme = getThemeSlug(account.activeThemeSlug);

      if (currentTheme.id !== newTheme) replaceTheme(newTheme);
    }
  }, [account]);

  useEffect(() => {
    if (
      status &&
      !!window?.Notification /* W3C Specification */
      // window?.webkitNotifications /* old WebKit Browsers */ ||
      // navigator.mozNotification
    ) {
      try {
        window.Notification.requestPermission().then(() => {});
      } catch (error) {
        // log(error?.message);
        // Promise.resolve(Notification.requestPermission()).then((permission) => {});
      }
    }
  }, [status]);

  return (
    <Fragment>
      {status ? (
        screenSaver ? (
          <div className='user-layout-container' id='user-layout-container'>
            <div className='screen-saver-back'>
              <SmallButton clicked={() => setScreenSaverState(false)}>
                {Strings.back_to_magictask}
              </SmallButton>
            </div>
            {triggerData?.riveTriggers?.bgAnimationHigh &&
              accountData?.accountSettings?.userAnimationSettings?.bgAnimationHigh && (
                <BgAnimation className='screen-saver-bg-animation' />
              )}
          </div>
        ) : (
          <div className='user-layout-container' id='user-layout-container'>
            <Suspense fallback={null}>
              {location.state &&
              location.state.path &&
              location.state.path.startsWith('/personal-info') ? (
                <PersonalInfoModal currentRoute={location.state} />
              ) : null}
            </Suspense>
            <TopBar currentRoute={props.path} />
            <LeftBar />
            <Suspense fallback={null}>
              <WorkspaceSwitch />
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showWhatsNewModal ? <WhatsNewModal /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showCreateNewProjectModal ? <CreateNewProject /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {showNotificationPermission ? <NotificationPermission /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showNotificationPopup ? <Notifications /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showCreateFolderModal ? <CreateNewFolder /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showInviteMembersModal ? <InviteMembers /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showUserProfileModal ? <UserProfile /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showSearchModal ? <Search /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.taskDetailModal.visible ? (
                <TaskInfo currentRoute={props.path} />
              ) : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.multimediaModal.visible ? <MultiMediaModal /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showEditProjectModal ? (
                <EditProject stateType={props.stateType} />
              ) : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showFeedbackModal ? <Feedback /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showCreateWorkspaceModal ? <CreateWorkspace /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.showCreateProjectTemplateModal ? <CreateProjectTemplate /> : null}
            </Suspense>
            <Suspense fallback={null}>
              {navigationData.messageModal.visible ? (
                <MessageModal
                  message={navigationData.messageModal.message}
                  authToken={navigationData.messageModal.authToken}
                />
              ) : null}
            </Suspense>
            <FullScreenLoader
              title={getWebSocketLoaderTitle()}
              hide={websocketStatus === 'CONNECTED' ? true : false}
            />
            <div id='main-page-container-user' className='main-page-container'>
              {/** @ts-ignore */}
              <Outlet context={props} />
            </div>
          </div>
        )
      ) : (
        <Navigate to='/login' />
      )}
    </Fragment>
  );
}
