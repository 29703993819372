import { useEffect, Fragment, useState } from 'preact/compat';
import { Strings } from 'resources';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'components/UI/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activateDeactivateWorkspace as activateDeactivateWorkspaceAPI } from 'services/SuperAdminService';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { formatDateForMeeting, isManageWorkspacesScreen } from 'utils/task';
import _ from 'underscore';
import handleViewport from 'react-in-viewport';
import { useRecoilState, useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import { superAdminDataSelector, workspacesDataState } from 'recoil/SuperAdminState';
import { getWorkspaces } from 'recoil/SuperAdminState/update';

export default function ManageWorkspaces() {
  const navigationData = useRecoilValue(navigationDataSelector);
  const superAdminData = useRecoilValue(superAdminDataSelector);
  const [workspaceData, setWorkspaceData] = useState<WorkspaceObjectType[]>([]);

  useEffect(() => {
    setWorkspaceData(
      superAdminData.workspaces.data.filter((item) => {
        return superAdminData.workspaces.status === 'all' ? item.active : !item.active;
      }),
    );
  }, [superAdminData]);

  useEffect(() => {
    if (isManageWorkspacesScreen(navigationData.currentRoute)) resetData();
  }, [navigationData.currentRoute]);

  const resetData = () => {
    getWorkspaces(true);
  };

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) loadMoreData();
    return (
      <div className='viewport-block' ref={forwardedRef}>
        <div style={{ paddingBottom: '0px' }}>{inViewport ? renderLoading() : ''}</div>
      </div>
    );
  };

  const getSortIcon = (name: SortByType) => {
    const { sortBy, sortOrder } = superAdminData.workspaces;
    if (sortBy === name) {
      if (sortOrder === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }

    return faSortAmountDown;
  };

  const isSelected = (name: SortByType) => {
    const { sortBy } = superAdminData.workspaces;
    if (sortBy === name) return 'selected';
    return '';
  };

  const renderLoading = () => {
    const { isLoading, hasMore } = superAdminData.workspaces;
    if (isLoading && hasMore)
      return (
        <div style={{ paddingLeft: '30px' }}>
          <Spinner />
        </div>
      );

    return null;
  };

  const loadMoreData = async () => {
    const { isLoading, hasMore } = superAdminData.workspaces;

    if (isLoading || !hasMore) return;

    getWorkspaces(false);
  };

  const workspaceActivationUpdated = async (
    index: number,
    workspaceId: string,
    active: boolean,
  ) => {
    const { success } = await activateDeactivateWorkspaceAPI(workspaceId, active);

    if (success) updateWorkspaceActiveStatus(index, active);
  };

  const [workspaces, setWorkspaces] = useRecoilState(workspacesDataState);
  const updateWorkspaceActiveStatus = (index: number, active: boolean) => {
    const tempData = workspaces.map((item, ind) => {
      if (index === ind)
        return {
          ...item,
          active,
        };
      return item;
    });
    setWorkspaces(tempData);
  };

  const renderWorkspaceActive = (workspace: WorkspaceObjectType, index: number) => {
    return (
      <Fragment>
        <span className='workspace-active'>
          {/** @ts-ignore */}
          <Switch
            onChange={(nextChecked) => workspaceActivationUpdated(index, workspace.id, nextChecked)}
            checked={workspace.active}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </span>
      </Fragment>
    );
  };

  const sortBy = (name: SortByType) => {
    getWorkspaces(true, name);
  };

  const sortStatus = () => {
    const { status } = superAdminData.workspaces;
    let value = 'all';
    if (status === 'all') {
      value = 'inactive';
    }
    getWorkspaces(true, undefined, value);
  };

  const renderWorkspacesTable = () => {
    return (
      <table>
        <thead>
          <tr className='workspace-container-head'>
            <th className='workspace-item name-item'>
              <span className='workspace-name-con'>
                <span
                  className={`sort-icon ${isSelected('workspace')}`}
                  onClick={() => sortBy('workspace')}
                >
                  <FontAwesomeIcon icon={getSortIcon('workspace')} />
                </span>
                {Strings.workspace_name_placeholder}
              </span>
            </th>
            <th className='workspace-item'>
              <span className='workspace-dates'>
                <span
                  className={`sort-icon ${isSelected('joiningDate')}`}
                  onClick={() => sortBy('joiningDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('joiningDate')} />
                </span>
                {Strings.creation_date}
              </span>
            </th>
            <th className='workspace-item'>
              <span className='workspace-dates'>
                <span
                  className={`sort-icon ${isSelected('lastLogin')}`}
                  onClick={() => sortBy('lastLogin')}
                >
                  <FontAwesomeIcon icon={getSortIcon('lastLogin')} />
                </span>
                {` ${Strings.last_login}`}
              </span>
            </th>
            <th className='workspace-item'>
              <span className='workspace-email'>
                <span
                  className={`sort-icon ${isSelected('lastLoggedInEmail')}`}
                  onClick={() => sortBy('lastLoggedInEmail')}
                >
                  <FontAwesomeIcon icon={getSortIcon('lastLoggedInEmail')} />
                </span>
                {` ${Strings.last_login_email}`}
              </span>
            </th>
            <th className='workspace-item center-align'>
              <span className='workspace-total-team'>
                <span
                  className={`sort-icon ${isSelected('activeUserCount')}`}
                  onClick={() => sortBy('activeUserCount')}
                >
                  <FontAwesomeIcon icon={getSortIcon('activeUserCount')} />
                </span>
                {Strings.total_team_members}
              </span>
            </th>
            <th className='workspace-item center-align'>
              <span className='workspace-total-tasks'>
                <span
                  className={`sort-icon ${isSelected('totalTasksInWorkspace')}`}
                  onClick={() => sortBy('totalTasksInWorkspace')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalTasksInWorkspace')} />
                </span>
                {Strings.total_tasks_created}
              </span>
            </th>
            <th className='workspace-item center-align'>
              <span className='workspace-total-tasks workspace-total-projects'>
                <span
                  className={`sort-icon ${isSelected('totalProjectsCreated')}`}
                  onClick={() => sortBy('totalProjectsCreated')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalProjectsCreated')} />
                </span>
                {Strings.total_projects_created}
              </span>
            </th>
            <th className='workspace-item center-align'>
              <span className='workspace-tutorial-progress'>{Strings.tutorial_progress}</span>
            </th>
            <th className='workspace-item'>
              <span className='workspace-email'>
                <span
                  className={`sort-icon ${isSelected('workspaceOwner')}`}
                  onClick={() => sortBy('workspaceOwner')}
                >
                  <FontAwesomeIcon icon={getSortIcon('workspaceOwner')} />
                </span>
                {Strings.owner_email}
              </span>
            </th>
            <th className='workspace-item'>
              <span className='workspace-active'>
                <span
                  className={`sort-icon ${isSelected('active')}`}
                  onClick={() => sortBy('active')}
                >
                  <FontAwesomeIcon icon={getSortIcon('active')} />
                </span>
                {` ${Strings.active}`}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {workspaceData.map((workspace, index) => {
            return renderWorkspace(workspace, index);
          })}
        </tbody>
      </table>
    );
  };

  const getWorkspaceTutorialProgress = (tutorialCount?: number | null) => {
    if (tutorialCount === undefined || tutorialCount === null) return 0;
    if (_.isEmpty(tutorialCount)) return 0;
    const steps = Object.values(tutorialCount);
    const totalSteps = steps.reduce((partialSum, a) => partialSum + a, 0);
    return `${totalSteps}/111`;
  };

  const ViewportBlock = handleViewport(Block, { rootMargin: '0px' });

  const renderWorkspace = (workspace: WorkspaceObjectType, index: number) => {
    return (
      <tr className='workspace-in-container' key={index}>
        <td className='workspace-item workspace-name'>
          <Link to={`/super-admin/workspace/${workspace.workspace}/${workspace.id}`}>
            {/* @ts-ignore */}
            <span>{workspace.workspace}</span>
          </Link>
        </td>
        <td className='workspace-item'>
          <span className={`workspace-dates ${workspace.joiningDate ? '' : 'hide'}`}>
            {workspace.joiningDate ? formatDateForMeeting(workspace.joiningDate) : ''}
          </span>
        </td>
        <td className='workspace-item'>
          <span className={`workspace-dates ${workspace.lastLogin ? '' : 'hide'}`}>
            {workspace.lastLogin ? formatDateForMeeting(workspace.lastLogin) : ''}
          </span>
        </td>
        <td className='workspace-item'>
          <span className='last-login-email'>{workspace.lastLoggedInEmail}</span>
        </td>
        <td className='workspace-item center-align'>
          <span className='count'>{workspace.activeUserCount}</span>
        </td>
        <td className='workspace-item center-align'>
          <span className={`count ${workspace.totalTasksInWorkspace !== null ? '' : 'hide'}`}>
            {workspace.totalTasksInWorkspace}
          </span>
        </td>
        <td className='workspace-item center-align'>
          <span className={`count ${workspace.totalProjectsCreated !== null ? '' : 'hide'}`}>
            {workspace.totalProjectsCreated}
          </span>
        </td>
        <td className='workspace-item center-align'>
          <span
            className={`count ${
              workspace.tutorialSteps !== null || workspace.tutorialSteps !== undefined
                ? ''
                : 'hide'
            }`}
          >
            {getWorkspaceTutorialProgress(workspace.tutorialSteps)}
          </span>
        </td>
        <td className='workspace-item'>
          <span>{workspace.workspaceOwner}</span>
        </td>
        <td className='workspace-item'>{renderWorkspaceActive(workspace, index)}</td>
      </tr>
    );
  };

  // const renderLoader = () => {
  //   return null;
  // };

  return (
    <div
      className={`manage-workspaces-screen ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div className='title-container'>
        <div className='page-title'>{Strings.super_admin}</div>
      </div>
      <div className='subtitle-container'>
        <div className='list-title'>{Strings.manage_workspaces}</div>
        <div className='checkbox-container'>
          <input
            type='checkbox'
            id='inactive'
            name='inactive'
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            checked={superAdminData.workspaces.status === 'inactive'}
            onChange={() => sortStatus()}
          />
          <label htmlFor='inactive'>{Strings.show_inactive}</label>
        </div>
      </div>
      <div className='manage-workspaces-list-con'>
        {renderWorkspacesTable()}
        <ViewportBlock key='load-next' />
      </div>
    </div>
  );
}
